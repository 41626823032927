import React, { useEffect } from "react";
import { Box, makeStyles, fade, Checkbox, withStyles, FormHelperText } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DoneAllRounded } from "@material-ui/icons";
import { useConfig } from "src/context/ConfigContext";
import { useFormContext } from "react-hook-form";

type PropTypes = {
  text: string;
};

export const ConsentNotice = ({ text }: PropTypes) => {
  const {
    register,
    formState: { errors, isValid },
  } = useFormContext();
  const { config } = useConfig();
  const styles = useStyles(config);

  return (
    <Box>
      <Alert
        variant="standard"
        severity="warning"
        icon={<DoneAllRounded />}
        // classes={{
        //   root: styles.background,
        //   message: styles.message,
        //   icon: styles.icon,
        // }}
      >
        {text}
      </Alert>
    </Box>
  );
};

const useStyles = (config: any) => {
  return makeStyles((theme) => ({
    background: {
      backgroundColor: fade(config.COLORS.primary, 0.06),
    },

    message: {
      color: config.COLORS.primary,
      display: "flex",
      alignItems: "center",
    },
    icon: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      width: 30,
      "& svg": {
        color: config.COLORS.primary,
      },
    },
    errorMessage: {
      color: "#F44336",
      lineHeight: 1.2,
      fontSize: "12px",
      // fontFamily: "Roboto, sans-serif",
    },
  }))();
};
