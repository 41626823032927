import React from "react";
import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export default function ErrorChip({ formik, config }) {
  const { isValid, submitCount } = formik;

  return (
    <>
      {!isValid && submitCount > 0 && (
        <Box mt={2}>
          <Alert severity="warning">Please check your Billing Details.</Alert>
        </Box>
      )}
    </>
  );
}
