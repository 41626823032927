import React, { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  makeStyles,
  colors,
} from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";
import Banner from "src/components/Banner/Banner.jsx";
import Header from "src/components/Header/Header";
import { RegistrationForm } from "./RegistrationForm";
import ConnectAccountCard from "./ConnectAccountCard";
import PlanCard from "./PlanCard";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Preloader from "./Preloader";
import { toast, ToastContainer } from "react-toastify";
import { team_options, plans, PlanType } from "./team_options";
import { useRegistrationConfig } from "src/registrationConfig";
import swal from "sweetalert2";
import { RegistrationFormWizard } from "src/components/RegistrationFormWizard";
import { useRedirect } from "src/hooks/useRedirect";

export default function RegistrationPage(): ReactElement {
  const history = useHistory();
  const { config } = useConfig();
  const { formWizard } = useRegistrationConfig(config?.TEAM_ID);
  const styles = useStyles(config);
  const teamId = config.TEAM_ID;
  const { plan, dotEduCheck } = useRegistrationConfig(teamId);
  // const [plan] = useState(plans.find((p: PlanType) => p.teamId === teamId) as PlanType);
  const [planSelected, setPlanSelected] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [accountConnected, setAccountConnected] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const handlePlanSelect = () => setPlanSelected(true);
  const [connectLoading, setConnectLoading] = useState<boolean>(false);

  const errorToast = (error: any): void => {
    toast.error(
      error?.response?.data?.message ||
        error?.response?.data ||
        "Error connecting team account, Please try again."
    );
  };

  const handleAuthUrl = async () => {
    setConnectLoading(true);
    const teamAuthUrl = `${config.ADMIN_URI}/auth/url/${config.TEAM_ID}`;
    let redirectUri = `${config.SS_FE_BASE_URI}/tmcallback/register/${teamId}/`;

    try {
      const teamAuthResponse = await axios.get(teamAuthUrl);
      const authUrl = teamAuthResponse.data.url;
      const tmUrl = authUrl
        .replace("REDIRECT_URL", redirectUri)
        .replace("STATE", `LOGIN-${config.TEAM_ID}`);
      window.location.href = tmUrl;
    } catch (error) {
      setConnectLoading(false);
      errorToast(error);
    }
  };

  const handleLogin = async () => {
    const endpoint = `${config.ADMIN_URI}/auth/teamLogin`;
    const url = history.location.search;
    const params = new URLSearchParams(url);
    const authCode = params.get("code");
    const redirectUri = `${config.SS_FE_BASE_URI}/tmcallback/register/${teamId}/`;

    if (!history.location.pathname.includes("tmcallback/register")) {
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(endpoint, {
        teamId,
        authCode,
        redirectUri,
      });
      if (response.status === 200) {
        const user: { [key: string]: any } = response.data;
        setAccountConnected(true);
        setUser(user);
      }
    } catch (error) {
      errorToast(error);
    }
    setLoading(false);
  };

  // .edu email validation and error handling
  const validateEduEmail = async () => {
    const email = user?.emailAddress;
    if (email) {
      let isEdu = email.substring(email.length - 4) == ".edu";
      if (!isEdu && dotEduCheck) {
        setAccountConnected(false);
        setPlanSelected(false);
        let tmLogOutUrl = `https://auth.ticketmaster.com/archtics/sign-out?redirectUrl=${config?.SS_FE_BASE_URI}/register/${config?.TEAM_ID}`;
        await swal
          .fire({
            icon: "error",
            html: "<p>This program is only available to current college or graduate school students. Please use your official school’s email address (@.edu) to complete your registration.</p>",
          })
          .then((res) => {
            if (res.isConfirmed) {
              if (window && window?.location) {
                window.location.href = tmLogOutUrl;
              } else {
                document.location.href = tmLogOutUrl;
              }
            }
          });
        // window.location.href = `/register/${teamId}`;
      }
    }
  };

  // Running side effects
  useEffect(() => {
    handleLogin();
  }, []);
  useEffect(() => {
    validateEduEmail();
  }, [user]);

  const { isRedirecting } = useRedirect({
    teamId,
    currentPage: "registration",
  });

  return (
    <>
      {isRedirecting ? (
        <>
          <Box>
            <ToastContainer />
            <Box>
              <Header />
              <Banner title="" subtitle="" loggedIn="" />
            </Box>

            {loading ? (
              <Box mt={4}>
                <Container>
                  <Grid
                    container
                    justify="center"
                    spacing={4}
                    alignContent="center"
                  >
                    <Grid item xs={11} sm={6} md={4} lg={4}>
                      <Preloader />
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            ) : (
              <Box mt={4}>
                <Container>
                  {!accountConnected && (
                    <Grid
                      container
                      spacing={4}
                      justify="center"
                      alignContent="center"
                    >
                      <Grid item xs={11} sm={7}>
                        <Box my={2} textAlign="center">
                          <Typography
                            variant="body1"
                            className={styles.description}
                          >
                            {config.UI_LANGUAGES?.registration?.description}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {!planSelected && !accountConnected && (
                    <Grid
                      container
                      justify="center"
                      spacing={4}
                      alignContent="center"
                    >
                      <Grid item xs={11} sm={6} md={4} lg={4}>
                        <PlanCard {...{ handlePlanSelect, plan }} />
                      </Grid>
                    </Grid>
                  )}

                  {planSelected && !accountConnected && (
                    <Grid
                      container
                      justify="center"
                      spacing={4}
                      alignContent="center"
                    >
                      <Grid item xs={11} sm={6} md={4} lg={4}>
                        <ConnectAccountCard
                          {...{ handleAuthUrl, connectLoading, plan }}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {accountConnected && (
                    <Grid container justify="center" spacing={4}>
                      <Grid item xs={12} md={8} lg={7}>
                        {!formWizard ? (
                          <RegistrationForm {...{ user }} />
                        ) : (
                          <RegistrationFormWizard {...{ user }} />
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Container>
              </Box>
            )}

            <Box pb={24} />
          </Box>
        </>
      ) : null}
    </>
  );
}

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    description: {
      fontFamily: "Poppins",
      color: colors.grey[600],
    },
  }))();
