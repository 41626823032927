import { Box, Grid, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { InputField } from "./InputField";
import {
  User as UserIcon,
  Smartphone as PhoneIcon,
  Mail as MailIcon,
  CreditCard as CardIcon,
} from "react-feather";
import { useCheckoutCtx } from "./CheckoutFormV2";
import { useEffect } from "react";
import { useConfig } from "src/context/ConfigContext";
import { CardExpiry } from "./CardExpiry";
import { formatCreditCardNumber, formatExpirationDate } from "src/utils/utils";

export const PaymentDetails = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const { config } = useConfig();
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const handleCardInput = (event: any) => {
    const value = event.target.value;
    const formatted = formatCreditCardNumber(value);
    setValue("cardNumber", formatted, { shouldValidate: true });
  };

  const handleExpiryInput = (event: any) => {
    const value = event?.target.value;
    const formatted = formatExpirationDate(value);
    setValue("cardExpiry", formatted, { shouldValidate: true });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems={xs ? "flex-start" : "center"}
          justifyContent={xs ? "flex-start" : "space-between"}
          flexDirection={xs ? "column" : "row"}
        >
          <Typography
            style={{
              fontSize: 18,
              fontFamily: "Poppins",
              fontWeight: 700,
            }}
            variant="h6"
            color="textPrimary"
          >
            Payment Method
          </Typography>
          <img style={{ width: 150, height: "auto" }} src={config.CHECKOUT_CARD_IMAGES} alt="" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <InputField
          placeholder="0000 0000 0000 0000"
          error={errors?.cardNumber?.message}
          endAdornment={<CardIcon />}
          {...register("cardNumber", {
            required: "Please enter Card Number",
            minLength: { value: 17, message: "Please enter 15/16 digit card number." },
            maxLength: { value: 20, message: "Please enter 15/16 digit card number." },
          })}
          onChange={handleCardInput}
        />
      </Grid>
      <Grid item xs={6}>
        <CardExpiry />
      </Grid>
      <Grid item xs={6}>
        <InputField
          placeholder="Security Code"
          error={errors?.cardCvc?.message}
          {...register("cardCvc", {
            required: "Please enter CVV",
            minLength: { value: 3, message: "Please enter 3/4 digit CVV" },
            maxLength: { value: 4, message: "Please enter 3/4 digit CVV" },
          })}
        />
      </Grid>
    </Grid>
  );
};
