import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function Page404() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.wrapper}>
      <Box textAlign="center" p={2}>
        <Box>
          <Typography
            variant="h1"
            color="textPrimary"
            className={classes.mainTitle}
          >
            Oops!
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.title}
          >
            404 - Page Not Found
          </Typography>
        </Box>

        <Box mt={2}>
          <Grid container justify="center">
            <Grid item xs={10} lg={7}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.subtitle}
              >
                The page you are looking for is no longer available.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {/* <Box mt={3}>
          {token && subscriber ? (
            <Button
              disableElevation
              variant="contained"
              size="large"
              className={classes.button}
              onClick={() => history.push(loginRedirect)}
            >
              MEMBERSHIP PORTAL
            </Button>
          ) : (
            <Button
              disableElevation
              variant="contained"
              size="large"
              className={classes.button}
              onClick={() => history.push(homeRedirect)}
            >
              HOME
            </Button>
          )}
        </Box> */}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainTitle: {
    fontSize: 200,
    fontWeight: 700,
    fontFamily: "Poppins",
    [theme.breakpoints.down("xs")]: {
      fontSize: 96,
    },
  },
  title: {
    fontSize: 48,
    fontWeight: 600,
    fontFamily: "Poppins",

    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
    },
  },
  subtitle: {
    fontSize: 22,
    fontFamily: "Poppins",
    fontWeight: 500,
    lineHeight: 1.2,

    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  //   button: {
  //     backgroundColor: config.COLORS.primary,
  //     color: "white",
  //     fontSize: "20px",
  //     padding: "15px 80px",
  //     borderRadius: 35,
  //     "&:hover": {
  //       backgroundColor: config.COLORS.secondary,
  //       color: config.COLORS.contrastText,
  //     },

  //     [theme.breakpoints.down("xs")]: {
  //       padding: "15px 50px",
  //     },
  //   },
}));
