import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useFormContext } from "react-hook-form";

export const ErrorChip = () => {
  const {
    formState: { isValid, submitCount, errors },
  } = useFormContext();

  return (
    <>
      {Object.keys(errors).length > 0 && submitCount > 0 && (
        <Box mt={2}>
          <Alert severity="warning">Please check your Billing Details.</Alert>
        </Box>
      )}
    </>
  );
};
