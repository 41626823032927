import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Paper,
  Box,
  Button,
  makeStyles,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  colors,
  CircularProgress,
} from "@material-ui/core";
import * as yup from "yup";
import BillingDetails from "./BillingDetails";
import CardInfo from "./CardInfo";
import AmexImage from "../../images/cards/amex.svg";
import VisaImage from "../../images/cards/visa.svg";
import DiscoverImage from "../../images/cards/discover.svg";
import MasterCardImage from "../../images/cards/mastercard.svg";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object().shape({
  // User details
  fullName: yup.string().min(2, "Please insert a valid name"),
  emailAddress: yup.string().email("Please insert a valid email address"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number is not valid")
    .max(10, "Phone number is not valid"),
  // Card details
  firstName: yup.string().min(1, "Please insert a first name"),
  lastName: yup.string().min(1, "Please insert a last name"),
  cardNumber: yup
    .string()
    .min(17, "Please insert a valid card number")
    .max(20, "Please insert a valid card number"),
  cardCvc: yup
    .string()
    .min(3, "Please insert a valid card cvc")
    .max(4, "Please insert a valid card cvc"),
  cardExpiry: yup
    .string()
    .min(4, "Please insert a valid card expiry")
    .max(5, "Please insert a valid card expiry"),
  address: yup.string().min(2, "Please insert a valid address"),
  postalCode: yup
    .number()
    .min(10000, "Please enter a valid Postal Code")
    .max(99999, "Please enter a valid Postal Code"),
});

export default function CheckoutForm({
  user,
  cartTotalAmount,
  config,
  sellClaimTickets,
  loading,
}) {
  const useStyles = makeStyles((theme) => ({
    cardTitle: {
      fontFamily: "Raleway",
      fontWeight: "700",
      fontSize: 20,

      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
      },
    },
    requiredText: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: colors.red["700"],
      fontSize: 14,
    },

    titleCardImage: {
      width: 42,
      marginLeft: 5,

      [theme.breakpoints.down("xs")]: {
        width: 28,
      },
    },
    tmButton: {
      backgroundColor: config.COLORS.primary,
      color: "white",
      fontSize: 22,
      "&:hover": {
        backgroundColor: config.COLORS.secondary,
        color: config.COLORS.contrastText,
      },
    },
  }));
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({
    accountNumber: user?.accountNumber || 0,
    fullName: user?.fullName || "",
    phoneNumber: user?.phoneNumber ? user?.phoneNumber.slice(-10) : "",
    emailAddress: user?.emailAddress || "",
    firstName: "",
    lastName: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
    address: "",
    postalCode: "",
  });
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const onSubmit = (values) => {
    console.log(values);
    sellClaimTickets(values);
  };
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  const { handleSubmit, dirty, isValid } = formik;

  useEffect(() => {
    if (cartTotalAmount === 0) {
      let formFields = {
        fullName: "",
        phoneNumber: "",
        emailAddress: "",
      };
      setInitialValues(formFields);
    }
  }, [cartTotalAmount]);

  return (
    <form onSubmit={handleSubmit}>
      <Paper variant="outlined">
        <Box
          p={1}
          pl={2}
          pr={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography
              variant="h5"
              className={classes.cardTitle}
              color="textPrimary"
            >
              Billing Details
            </Typography>
          </Box>
        </Box>
        <Divider />

        <Box p={xs ? 2 : 4} pb={0} pt={2}>
          <Typography variant="caption" className={classes.requiredText}>
            *** All fields are required
          </Typography>
        </Box>

        <Box p={xs ? 2 : 4} pt={1}>
          <BillingDetails {...{ formik, config }} />
        </Box>
      </Paper>

      {cartTotalAmount > 0 && (
        <Paper variant="outlined" style={{ marginTop: 30 }}>
          <Box
            p={1}
            pl={2}
            pr={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Typography
                variant="h5"
                className={classes.cardTitle}
                color="textPrimary"
              >
                Payment Method
              </Typography>
            </Box>
            <Box>
              <img
                alt="Visa"
                src={VisaImage}
                className={classes.titleCardImage}
              />
              <img
                alt="MasterCard"
                src={MasterCardImage}
                className={classes.titleCardImage}
              />
              <img
                alt="Discover"
                src={DiscoverImage}
                className={classes.titleCardImage}
              />
              <img
                alt="Amex"
                src={AmexImage}
                className={classes.titleCardImage}
              />
            </Box>
          </Box>
          <Divider />

          <Box p={xs ? 2 : 4} pb={0} pt={2}>
            <Typography variant="caption" className={classes.requiredText}>
              *** All fields are required
            </Typography>
          </Box>

          <Box p={xs ? 2 : 4} pt={1}>
            <CardInfo {...{ formik, config }} />
          </Box>
        </Paper>
      )}

      <Paper variant="outlined" style={{ marginTop: 30 }}>
        <Box p={xs ? 2 : 4}>
          {cartTotalAmount > 0 ? (
            <Button
              variant="contained"
              disableElevation
              size="large"
              fullWidth
              className={classes.tmButton}
              type="submit"
              disabled={!isValid}
            >
              PURCHASE
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ marginLeft: 8, marginTop: -2, color: "white" }}
                />
              )}
            </Button>
          ) : (
            <Button
              variant="contained"
              disableElevation
              size="large"
              fullWidth
              className={classes.tmButton}
              type="submit"
              disabled={!isValid}
            >
              Claim ticket
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ marginLeft: 8, marginTop: -2, color: "white" }}
                />
              )}
            </Button>
          )}
        </Box>
      </Paper>
    </form>
  );
}
