import { Grid, Button, CircularProgress, colors, makeStyles, lighten } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { useConfig } from "src/context/ConfigContext";

export const Submit = () => {
  const { config } = useConfig();
  const styles: Record<string, any> = useStyles(config);
  const {
    formState: { isSubmitting },
  } = useFormContext();
  return (
    <Grid item xs={12}>
      <Button
        disableElevation
        variant="contained"
        fullWidth
        className={styles.submitButton}
        type="submit"
      >
        Register
        {isSubmitting && (
          <CircularProgress
            size={16}
            style={{ color: config?.COLORS?.contrastText, marginLeft: 10 }}
          />
        )}
      </Button>
    </Grid>
  );
};

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    submitButton: {
      backgroundColor: config?.COLORS.primary,
      color: config?.COLORS.contrastText,
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 20,

      "&:hover": {
        backgroundColor: lighten(config?.COLORS.primary, 0.1),
      },
    },
  }))();
