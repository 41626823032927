import { InputField } from "./utils/InputField";
import { useFormContext } from "react-hook-form";
import { User as UserIcon, Smartphone as PhoneIcon, Mail as MailIcon } from "react-feather";
import { Box } from "@material-ui/core";

type PropTypes = {
  name: string;
};

export const PhoneNumber = ({ name }: PropTypes) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputField
      placeholder="Cell Phone (Numbers Only)"
      error={errors[name]?.message}
      startAdornment={
        <Box
          style={{
            paddingRight: "4px",
            marginTop: "-2px",
            color: "rgba(0, 0, 0, 0.38)",
          }}
        >
          +1
        </Box>
      }
      endAdornment={<PhoneIcon />}
      {...register(name, {
        required: "Please enter Phone Number",
        minLength: {
          value: 10,
          message: "Please enter a valid phone number.",
        },
        maxLength: {
          value: 10,
          message: "Please enter a valid phone number.",
        },
        pattern: {
          value:
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          message: "Please enter a valid phone number.",
        },
      })}
    />
  );
};
