import React from "react";
import { makeStyles, Box, useTheme, useMediaQuery } from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";

export default function Header() {
  const { config } = useConfig();

  const useStyles = makeStyles(() => ({
    header: {
      display: "grid",
      gridTemplateColumns: "1fr",
      alignItems: "center",
      width: "100%",
    },
    siteLogo: {
      "& img": {
        width: 110,
      },
      display: "flex",
      justifyContent: "center",
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      {config?.HEADER_ENABLED && (
        <Box pl={xs ? 2 : 4} pr={xs ? 2 : 4} p={1}>
          <Box className={classes.header}>
            <Box className={classes.siteLogo}>
              <img src={config.SITE_LOGO_URI} alt="" />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
