import { Box, Grid, Typography, IconButton } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { InputField } from "./InputField";
import {
  Home as HomeIcon,
  Send as SendIcon,
  XCircle as CloseIcon,
  MapPin as MapIcon,
  Map as PlaceIcon,
} from "react-feather";
import { useCheckoutCtx } from "./CheckoutFormV2";
import { StreetAutocomplete } from "./StreetAutocomplete";
import { ManualAddressNotice } from "./ManualAddressNotice";
import { Controller } from "react-hook-form";

export const BillingDetails = () => {
  const {
    register,
    formState: { errors, submitCount },
    setValue,
    watch,
    trigger,
    control,
  } = useFormContext();
  const { isManualAddress } = useCheckoutCtx();

  const handleAddressChange = (address: { street?: string; zip?: string }) => {
    const { street, zip } = address;
    setValue("street", street ? street : "");
    setValue("zipCode", zip ? zip : "");
    trigger("street");
    trigger("zipCode");
  };

  const clearAddress = (event: any) => {
    event.stopPropagation();
    setValue("street", "");
    setValue("zipCode", "");
    submitCount > 0 && trigger("street");
    submitCount > 0 && trigger("zipCode");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontSize: 18,
            fontFamily: "Poppins",
            fontWeight: 700,
          }}
          variant="h6"
          color="textPrimary"
        >
          Billing Details
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ManualAddressNotice />
      </Grid>
      <Grid item xs={12}>
        {!isManualAddress && (
          <StreetAutocomplete onChange={handleAddressChange}>
            <InputField
              {...register("street", {
                required: "Please enter your street",
              })}
              role={"presentation"}
              // label="Street Address"
              placeholder="Street Address"
              error={errors?.street?.message}
              endAdornment={
                watch("street") ? (
                  <IconButton size={"small"} onClick={clearAddress}>
                    <CloseIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                  </IconButton>
                ) : (
                  <MapIcon />
                )
              }
            />
          </StreetAutocomplete>
        )}

        {isManualAddress && (
          <InputField
            {...register("street", {
              required: "Please enter your street",
            })}
            role={"presentation"}
            // label="Street Address"
            placeholder="Street Address"
            error={errors?.street?.message}
            endAdornment={<MapIcon />}
          />
        )}
      </Grid>

      {isManualAddress && (
        <>
          <Grid item xs={12} md={6}>
            <InputField placeholder="City" endAdornment={<PlaceIcon />} />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField placeholder="State" endAdornment={<PlaceIcon />} />
          </Grid>
        </>
      )}

      <Grid item xs={12} md={6}>
        <InputField placeholder="Apt/Unit" endAdornment={<HomeIcon />} />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          placeholder="Zip Code"
          error={errors?.zipCode?.message}
          endAdornment={<SendIcon />}
          {...register("zipCode", {
            required: "Please insert a valid zip code.",
          })}
        />
      </Grid>
    </Grid>
  );
};
