import { RegisterConfig } from "src/types";
import * as yup from "yup";

export const diamondbacks: RegisterConfig = {
  teamId: 1,
  ccEnabled: false,
  plan: {
    planName: "Diamondbacks Last Minute Pass",
    description:
      "To register, simply connect your Arizona Diamondbacks Team Account and provide your contact information to receive alerts for last-minute ticket offers.",
    tmTitle: "Connect D-Backs Account",
    tmDescription:
      "Please connect your D-Backs Account for ticket delivery. If you don’t have an Account you will be prompted to create a new one.",
  },
};
