import React, { useEffect } from "react";
import {
  Box,
  Grid,
  makeStyles,
  Container,
  Button,
  styled,
} from "@material-ui/core";
import { LocalMallOutlined } from "@material-ui/icons";
import { useCart } from "src/context/MultiCartContext";
import Total from "./Total";

export default function FloatingPanel({ config, setCheckout, multicart }) {
  const styles = useStyles();
  const { totalSeats } = useCart();

  const StyledButton = styled(Button)({
    backgroundColor: config.COLORS.primary,
    color: config.COLORS.contrastText,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
      color: config.COLORS.secondaryContrastText,
    },
  });

  const goToCheckout = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setCheckout(true);
  };

  return (
    <Box className={styles.panel}>
      <Container maxWidth={true}>
        <Grid container spacing={4} justify="center">
          <Grid item xs={12} lg={7}>
            <Box className={styles.contentWrapper}>
              <Total {...{ config }} />
              <Box>
                <StyledButton
                  disableElevation
                  disabled={!(totalSeats > 0)}
                  variant="contained"
                  className={styles.button}
                  onClick={goToCheckout}
                >
                  checkout
                </StyledButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  panel: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: 80,
    backgroundColor: "white",
    boxShadow: `0 0px 5.6px -13px rgba(0, 0, 0, 0.08),
  0 0px 45px -13px rgba(0, 0, 0, 0.16)`,
  },

  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 80,

    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  button: {
    borderRadius: 30,
    color: "white",
    width: 220,
    height: 45,
    fontSize: 20,
    fontWeight: 500,

    [theme.breakpoints.down("md")]: {
      marginLeft: 30,
    },
    [theme.breakpoints.down("xs")]: {
      width: 130,
      height: 40,
      fontSize: 16,
      marginLeft: 15,
    },
  },
}));
