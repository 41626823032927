import React from "react";
import {
  Paper,
  Box,
  Avatar,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
import { User as CheckSquareIcon } from "react-feather";

export default function LoginNotice({
  accountUserName,
  config,
  accountNumber,
}) {
  const useStyles = makeStyles((theme) => ({
    userName: {
      textDecoration: "underline",
      color: config.COLORS.primary,
    },
    accountNumber: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: 18,
      marginLeft: 5,
    },
  }));
  const classes = useStyles();

  return (
    <Paper style={{ marginTop: 10, marginBottom: 30 }} elevation={4}>
      <Box
        p={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box pr={2}>
          <Avatar
            style={{
              backgroundColor: colors.green["500"],
            }}
          >
            <CheckSquareIcon />
          </Avatar>
        </Box>
        <Box>
          <Box>
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: 18,
                fontWeight: "bold",
              }}
              color="textPrimary"
              variant="h6"
            >
              Logged into{" "}
              <span className={classes.userName}>{accountUserName}</span>
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                fontFamily: "Raleway",
                fontSize: 18,
                fontWeight: "bold",
              }}
              color="textPrimary"
              variant="h6"
            >
              Account #
              <span className={classes.accountNumber}>{accountNumber}</span>
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{ fontFamily: "Raleway", fontWeight: 500 }}
              color="textSecondary"
              variant="subtitle2"
            >
              If you want to change your account; please go to{" "}
              <a
                href={config.AM_PORTAL_URI}
                target="_blank"
                rel="noopener noreferrer"
              >
                ticketmaster.com
              </a>
              , log out, and return to this page
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
