import { RegisterConfig } from "src/types";
import * as yup from "yup";

export const kings: RegisterConfig = {
  teamId: 78,
  ccEnabled: false,
  plan: {
    planName: "Kings Last-Minute Ticket Deals",
    description:
      "To sign up, simply connect your Ticketmaster account and provide your contact information to receive alerts for last-minute ticket deals. If you don’t have a Ticketmaster account, you can create a new account.",
    tmTitle: "Connect Ticketmaster Account",
    tmDescription:
      "Please connect your Ticketmaster Account for ticket delivery. If you don’t have a Ticketmaster Account you will be prompted to create a new one.",
  },
};
