import { RegisterConfig } from "src/types";
import * as yup from "yup";

export const hawks_corporate: RegisterConfig = {
  teamId: 997,
  ccEnabled: false,
  plan: {
    planName: "Hawks Partner Pass",
    description:
      "To register, simply connect your Hawks Team Account and provide your contact information to receive alerts for last-minute ticket offers.",
    tmTitle: "Connect Hawks Account",
    tmDescription:
      "Please connect your Hawks Team Account for ticket delivery. If you don’t have a Team Account you will be prompted to create a new one.",
  },
  additionalFields: [
    {
      name: "source", // consider providing a name without space
      type: "input",
      label: "Which Hawks partner are you associated with?",
      placeholder: "Type here",
      required: "Please enter value in this field.",
      schema: yup.string().required("Please enter value in this field."),
    },
  ],
};
