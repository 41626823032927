import { withStyles, FormHelperText, Typography, Box } from "@material-ui/core";

export const ErrorMessage = ({ text }: { text?: string }) => {
  return (
    <Box
      style={{
        minHeight: "12px",
        backgroundColor: "transparent",
        marginLeft: "4px",
        marginTop: "2px",
      }}
    >
      {text && (
        <p
          style={{
            lineHeight: 1,
            color: "#f44336",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: "0.75rem",
            letterSpacing: "0.03333em",
            margin: 0,
          }}
        >
          {text}
        </p>
      )}
    </Box>
  );
};
