import React, { ReactElement, createElement } from "react";
import {
  Box,
  Paper,
  makeStyles,
  Typography,
  Hidden,
  Button,
  fade,
  colors,
  darken,
  CircularProgress,
} from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";
import { useHistory } from "react-router-dom";
import tmIcon from "src/images/tm-icon.png";
import { PlanType } from "./team_options";
import { RegistrationPlan } from "src/types";

type PropTypes = {
  handleAuthUrl: Function;
  connectLoading: boolean;
  plan: RegistrationPlan;
};

export default function ConnectAccountCard({
  handleAuthUrl,
  connectLoading,
  plan,
}: PropTypes): ReactElement {
  const history = useHistory();
  const { config } = useConfig();
  const teamId = config.TEAM_ID;
  const styles = useStyles(config);

  return (
    <Paper variant="outlined" className={styles.paper}>
      <Box className={styles.planContent} p={2}>
        <Box width="100%" boxSizing="border-box" textAlign="center">
          <Typography variant="h5" className={styles.planName}>
            {plan.tmTitle}
          </Typography>
        </Box>

        <Box mt={1} textAlign="center">
          <Typography variant="subtitle2" className={styles.planDescription}>
            {plan.tmDescription}
          </Typography>
        </Box>

        <Box width="100%" boxSizing="border-box" mt={2}>
          <Button
            disabled={connectLoading}
            size="large"
            fullWidth
            disableElevation
            variant="contained"
            className={styles.button}
            onClick={() => handleAuthUrl()}
          >
            <img src={tmIcon} alt="" className={styles.tmIcon} />
            Connect
            {connectLoading && <CircularProgress className={styles.spinner} size={18} />}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    paper: {
      padding: 8,
      height: "100%",
      boxSizing: "border-box",
      transition: "all 0.3s ease",
      "&:hover": {
        boxShadow: "0 5px 16px rgb(0 0 0 / 10%)",
      },
    },

    planContent: {
      position: "relative",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
      boxSizing: "border-box",
    },
    planName: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 18,
      color: colors.grey[800],
    },

    planDescription: {
      color: colors.grey[500],
      fontFamily: "Poppins",
    },

    button: {
      backgroundColor: "#026cdf",
      color: config?.COLORS.contrastText,
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: 16,
      position: "relative",

      "&:hover": {
        backgroundColor: darken("#026cdf", 0.2),
      },
    },

    tmIcon: {
      width: 10,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: 20,
    },
    spinner: {
      color: colors.grey[600],
      position: "absolute",
      right: 20,
    },
  }))();
