import { RegisterConfig } from "src/types";
import * as yup from "yup";

export const hawks: RegisterConfig = {
  teamId: 53,
  ccEnabled: false,
  dotEduCheck: false,
  plan: {
    planName: "Hawks Last Minute Pass",
    description:
      "To register, simply connect your Hawks Team Account and provide your contact information to receive alerts for last-minute ticket offers.",
    tmTitle: "Connect Hawks Account",
    tmDescription:
      "Please connect your Hawks Team Account for ticket delivery. If you don’t have a Team Account you will be prompted to create a new one.",
  },

  additionalFields: [
    {
      name: "source", // consider providing a name without space
      type: "checkbox",
      label: "Where did you learn about this program?",
      required: "Please select one value.",
      schema: yup.string().required("Please select one value."),
      options: [
        "Auburn",
        "Emory",
        "Mercer",
        "Georgia Tech",
        "Georgia State",
        "Kennesaw State",
        "West Georgia",
        "Spelman",
        "Morehouse",
        "UGA",
        "Other",
      ],
    },
  ],
};
