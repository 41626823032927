import LogoImage from "src/images/falcons/falcons_logo.png";
import CardImages from "src/images/cc-images.png";
const BannerImage =
  "https://ss-leagues.s3.amazonaws.com/mercedesbenzstadium2.png";

const config = {
  SITE_TITLE: "ATLANTA FALCONS",
  TEAM_NAME: "Atlanta Falcons",
  TEAM_ID: 84,
  PRODUCT_NAME: "Claim Product",
  SITE_LOGO_URI: LogoImage,
  IS_DEVELOPMENT: false,
  REGISTRATION_ENABLED: true,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  CHECKOUT_TERMS_ENABLED: true,
  HEADER_ENABLED: true,
  CARD_ON_FILE: true,
  DOT_EDU_CHECK: true,
  CARD_ON_FILE_ERROR: true,

  // API base URLs
  API_BASE_URI: "https://gators-subs-dev.herokuapp.com/api",
  SS_FE_BASE_URI: "https://claim.seasonshare.com",
  ADMIN_BASE_URI: "https://subs-janitor-prod.herokuapp.com/api",
  ADMIN_URI:
    "https://claim-backend-multi-product-6bd3c222ac79282b.onporter.run",

  // ADMIN_BASE_URI: "http://localhost:32290/api",
  // ADMIN_URI: "http://localhost:3001",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#A71930",
    secondary: "#000000",
    typography: "#130c0e",
    contrastText: "white",
    secondaryContrastText: "white",
    bannerOverlay: 0,
  },
  HOME_HERO_BG: BannerImage,
  CHECKOUT_CARD_IMAGES: CardImages,
  HOW_TO_IMAGE: "",

  PAGE_TITLES: {
    claimTicket: {
      title: "",
      subtitle: "",
    },
    thankYou: {
      title: "You're Good To Go!",
      subtitle:
        "We're excited to have you with us this season. You will receive a text message when your tickets are available in your Atlanta United Account Manager.",
    },
  },
  UI_LANGUAGES: {
    connectTeamAccount: {
      title: ``,
      description: `Mercedes-Benz Stadium College Pass is exclusively for current college and graduate school students looking to lock in last minute, low cost deals to see Atlanta Falcons games, Atlanta United matches and other exciting events at Mercedes-Benz Stadium. Register to receive exclusive ticket offers throughout the entire season. PLUS, if better seats are available, we’ll automatically upgrade you for free!`,
      button: "Login to Mercedes-Benz Stadium Account",
      disclaimer: `*Tickets are mobile-only and available through your Mercedes-Benz Stadium Account. Tickets are standing-room only unless assigned to seating locations a few hours prior to the event.`,
    },
    checkout: {
      terms: `By purchasing you agree to receiving SMS alerts regarding ticket
            delivery.`,
      purchaseButtonText: "Redeem Offer",
    },
    registration: {
      description: `Mercedes-Benz Stadium College Pass is exclusively for current college and graduate school students looking to lock in last minute, low cost deals to see Atlanta Falcons games, Atlanta United matches and other exciting events at Mercedes-Benz Stadium. Register to receive exclusive ticket offers throughout the entire season. PLUS, if better seats are available, we’ll automatically upgrade you for free!`,
    },
    registerSuccess: {
      title: "",
      description:
        "Thank you for registering for the Mercedes-Benz Stadium College Pass! Be on the lookout for future texts when discounted ticket offers to exciting events become available",
    },
  },
};

export default config;
