import { useConfig } from "src/context/ConfigContext";
import { FormControl, InputLabel, Select, MenuItem, makeStyles, colors } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import clsx from "clsx";

export const SelectField = ({
  label,
  options,
  value,
  onChange,
}: {
  value?: any;
  label?: string;
  options?: any[];
  onChange?: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void | undefined;
}) => {
  const { config } = useConfig();
  const styles = useStyles(config);
  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      className={clsx(styles?.inputField, styles?.yearInput)}
    >
      <InputLabel className={styles.inputLabel}>{label}</InputLabel>
      <Select onChange={onChange} value={value}>
        {options?.map((value) => (
          <MenuItem value={value} key={uuid()}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = (config: any) =>
  makeStyles((theme) => ({
    mainLabel: {
      color: colors.grey[800],
      fontWeight: 600,
      fontFamily: "Poppins",
      marginBottom: 3,
    },
    inputLabel: {
      "&.MuiInputLabel-shrink": {
        backgroundColor: "white",
      },
    },
    inputField: {
      "& .MuiInputBase-root": {
        color: colors.grey[600],
      },

      "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: config.COLORS.primary,
      },
      "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F44336",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: config.COLORS.primary,
      },
      "& .MuiFormLabel-root.Mui-error": {
        color: "#F44336",
      },
    },

    monthInput: {
      "& fieldset": {
        // borderRightColor: "transparent",
        // borderTopRightRadius: 0,
        // borderBottomRightRadius: 0,
      },
    },

    yearInput: {
      "& fieldset": {
        // borderLeftColor: "transparent",
        // borderTopLeftRadius: 0,
        // borderBottomLeftRadius: 0,
      },
    },
  }))();
