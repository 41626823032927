import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  typography: {
    h1: { fontFamily: `Raleway` },
    h2: { fontFamily: `Raleway` },
    h3: { fontFamily: `Raleway` },
    h4: { fontFamily: `Raleway` },
    h5: { fontFamily: `Raleway` },
    h6: { fontFamily: `Raleway` },
  },
});
