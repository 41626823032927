import Alert from "@material-ui/lab/Alert";

export const InfoAlert = () => {
  return (
    <Alert severity="info">
      A credit card number is required to complete registration. Failure to do so will result in an
      incomplete registration and you will not receive exclusive ticket deals until a credit card is
      added to your account.
    </Alert>
  );
};
