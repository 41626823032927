import { createContext, useContext, useEffect, useState } from "react";
import { PersonalDetailsForm } from "./PersonalDetailsForm";
import { PaymentDetailsForm } from "./PaymentDetailsForm";
import type { CtxType, PaymentValues, PersonalValues, PropTypes } from "./types";
import axios from "axios";
import { useConfig } from "src/context/ConfigContext";
import { toast } from "react-toastify";
import swal from "sweetalert2";

// Creating wizard ctx
const WizardCtx = createContext({});
export const useWizardCtx = () => useContext(WizardCtx) as CtxType;

// Array of forms (steps)
const forms = [<PersonalDetailsForm />, <PaymentDetailsForm />];

// Actual Wizard component
export const RegistrationFormWizard = ({ user }: PropTypes) => {
  const { config } = useConfig();

  const teamId = config?.TEAM_ID;
  const { accountNumber } = user;
  const product = "claim";
  const [step, setStep] = useState(0);
  const [values, setValues] = useState<PersonalValues & PaymentValues>();
  const [submittingPersonal, setSubmittingPersonal] = useState<boolean>(false);
  const [submittingPayment, setSubmittingPayment] = useState<boolean>(false);

  // Registration api handler
  const handleRegister = async () => {
    if (!submittingPersonal) return;

    const createdAt = new Date();
    const endpoint = `${config.ADMIN_URI}/auth/generalRegister`;
    const payload = { product, createdAt, accountNumber, ...values };

    try {
      const response = await axios.post(endpoint, payload);
      if (response.status === 200) {
        toast.success("Successfully submitted");
        window.location.href = `/register_success/${teamId}`;
      }
    } catch (error: any) {
      swal
        .fire({
          icon: "error",
          text: "Your registration is incomplete. Please enter your credit card information to be able to receive exclusive ticket deals.",
          confirmButtonText: "Finish Registration",
          showCancelButton: true,
          cancelButtonText: "Exit",
        })
        .then((result) => {
          if (result.isDismissed) {
            window.location.href = `/register_failed/${teamId}`;
          }
        });
    }
    setSubmittingPersonal(false);
  };

  // Personal details submit
  const submitPersonalDetails = async (data: any) => {
    setValues({ ...values, ...data });
    setSubmittingPersonal(true);
    const createdAt = new Date();
    const endpoint = `${config.ADMIN_URI}/auth/generalRegister`;
    const payload = { product, createdAt, accountNumber, teamId, ...data };
    try {
      const response = await axios.post(endpoint, payload);
      if (response.status === 200) {
        setStep(step + 1);
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong. Please contact support if the error persists."
      );
    }
    setSubmittingPersonal(false);
  };

  // Payment details submit
  const submitPaymentDetails = async (data: any) => {
    setValues({ ...values, ...data });
    setSubmittingPayment(true);
    const endpoint = `${config.ADMIN_URI}/auth/registerCard`;
    let { termsAccepted, ccExp, ccNum, ...rest } = data as PaymentValues;
    ccExp = ccExp.replaceAll("/", "");
    ccNum = ccNum.replaceAll(" ", "");
    let phoneNumber = values?.phoneNumber;
    const payload = { accountNumber, teamId, ccExp, ccNum, phoneNumber, ...rest };
    try {
      const response = await axios.post(endpoint, payload);
      if (response.status === 200) {
        toast.success("Successfully submitted");
        window.location.href = `/register_success/${teamId}`;
      }
    } catch (error: any) {
      swal.fire({
        allowOutsideClick: false,
        icon: "error",
        text: "Please enter a valid credit card to be able to receive exclusive deals.",
        confirmButtonText: "Exit",
        showCancelButton: true,
        cancelButtonText: "Finish Registration",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const { phoneNumber } = values as PersonalValues;
          const endpoint = `${config.ADMIN_URI}/auth/incompleteRegText`;
          return axios
            .post(endpoint, { phoneNumber })
            .then((res) => {
              window.location.href = `/register_failed/${teamId}`;
            })
            .catch((error) => {
              toast.error(
                error?.response?.data?.message ||
                  "Something went wrong. Please contact support if the error persists."
              );
            });
        },
      });
    }
    setSubmittingPayment(false);
  };

  // Incomplete registration submit
  const submitIncomplete = () => {
    swal.fire({
      allowOutsideClick: false,
      icon: "error",
      text: "Your registration is incomplete. Please enter your credit card information to be able to receive exclusive ticket deals.",
      confirmButtonText: "Exit",
      showCancelButton: true,
      cancelButtonText: "Finish Registration",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const { phoneNumber } = values as PersonalValues;
        const endpoint = `${config.ADMIN_URI}/auth/incompleteRegText`;
        return axios
          .post(endpoint, { phoneNumber })
          .then((res) => {
            window.location.href = `/register_failed/${teamId}`;
          })
          .catch((error) => {
            toast.error(
              error?.response?.data?.message ||
                "Something went wrong. Please contact support if the error persists."
            );
          });
      },
    });
  };

  return (
    <WizardCtx.Provider
      value={{
        submitPersonalDetails,
        submitPaymentDetails,
        email: user?.emailAddress,
        submittingPersonal,
        submittingPayment,
        submitIncomplete,
      }}
    >
      {forms[step]}
    </WizardCtx.Provider>
  );
};
