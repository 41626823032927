import { RegisterConfig } from "src/types";
import * as yup from "yup";

export const sharks: RegisterConfig = {
  teamId: 138,
  ccEnabled: false,
  dotEduCheck: false,
  plan: {
    planName: "San Jose Sharks Last Minute Pass",
    description:
      "To register, simply connect your San Jose Sharks Team Account and provide your contact information to receive alerts for last-minute ticket offers.",
    tmTitle: "Connect San Jose Sharks Account",
    tmDescription:
      "Please connect your San Jose Sharks Team Account for ticket delivery. If you don’t have a Team Account you will be prompted to create a new one.",
  },

  additionalFields: [],
};
