import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, useParams } from "react-router-dom";
import ClaimTicketContainer from "./containers/ClaimTicket/ClaimTicket";
import ThankYouPage from "./containers/ThankYou/ThankYou";
import AllClaimPage from "src/pages/AllClaimPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Page404 from "src/pages/Page404";
import RegistrationPage from "./pages/RegistrationPage";
import RegistrationSuccessPage from "src/pages/RegistrationSuccessPage";
import { RegistrationFailedPage } from "src/pages/RegistrationFailedPage";
import { withValidateRoute } from "./components/withValidateRoute";

const AppContainer = () => {
  const AllClaim = withValidateRoute(AllClaimPage);
  const ClaimTicket = withValidateRoute(ClaimTicketContainer);
  const Registration = withValidateRoute(RegistrationPage);
  const RegistrationSuccess = withValidateRoute(RegistrationSuccessPage);
  const RegistrationFailed = withValidateRoute(RegistrationFailedPage);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          <Route path="/claim-ticket/all/:teamId/:claimPlanCode" exact component={AllClaim} />
          <Route path="/tmcallback/login/:teamId/:claimPlanCode" exact component={AllClaim} />
          {/* <Route
            path="/claim-ticket/:teamId/:eventId"
            exact
            component={ClaimTicket}
          />
          <Route
            path="/tmcallback/login0/:teamId/:eventId"
            exact
            component={ClaimTicket}
          /> */}
          <Route path="/thank-you/:teamId" exact component={ThankYouPage} />
          <Route path="/register/:teamId" exact component={Registration} />
          <Route path="/tmcallback/register/:teamId" exact component={Registration} />
          <Route path="/register_success/:teamId" exact component={RegistrationSuccess} />
          <Route path="/register_failed/:teamId" exact component={RegistrationFailed} />
          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default AppContainer;
