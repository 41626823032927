import React, { ReactElement, createElement } from "react";
import {
  Box,
  Paper,
  makeStyles,
  Typography,
  Hidden,
  Button,
  fade,
  colors,
  darken,
  CircularProgress,
} from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";
import { useHistory } from "react-router-dom";
import tmIcon from "src/images/tm-icon.png";
import { DoneAllRounded, CancelOutlined } from "@material-ui/icons";

export const FailedCard = () => {
  const { config } = useConfig();
  const teamId = config.TEAM_ID;
  const styles = useStyles(config);

  return (
    <Paper variant="outlined" className={styles.paper}>
      <Box className={styles.planContent} p={2}>
        <Box textAlign="center" width="100%" boxSizing="border-box">
          <CancelOutlined className={styles.errorIcon} />
        </Box>
        {/* <Box mt={1} width="100%" boxSizing="border-box" textAlign="center">
          <Typography variant="h5" className={styles.planName}>
            {config?.UI_LANGUAGES?.registerSuccess?.title}
          </Typography>
        </Box> */}

        <Box mt={1} textAlign="center">
          <Typography variant="subtitle2" className={styles.planDescription}>
            Thank you for your interest in the Mercedes-Benz Stadium College Pass. You will only be
            able to receive exclusive ticket deals by adding a credit card to your account. Your
            card will only be charged when you select to attend a specific event.
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    paper: {
      padding: 8,
      height: "100%",
      boxSizing: "border-box",
      transition: "all 0.3s ease",
      "&:hover": {
        boxShadow: "0 5px 16px rgb(0 0 0 / 10%)",
      },
    },

    planContent: {
      position: "relative",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
      boxSizing: "border-box",
    },
    errorIcon: {
      fontSize: 32,
      color: theme.palette.error.main,
    },
    planName: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 20,
      color: colors.grey[800],
    },

    planDescription: {
      color: colors.grey[500],
      fontFamily: "Poppins",
    },
  }))();
