import {
  Box,
  Grid,
  Select,
  FormControl,
  FormHelperText,
  makeStyles,
  colors,
  InputLabel,
  FormControlLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useForm, useFormContext, Controller } from "react-hook-form";
import { useConfig } from "src/context/ConfigContext";
import clsx from "clsx";
import { withStyles } from "@material-ui/styles";
import { ErrorMessage } from "./utils/ErrorMessage";
import { SelectField } from "./utils/SelectField";

// Helper fn
const sequenceArray = (start: number, end: number) => {
  const array: number[] = [];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
};
const months = sequenceArray(1, 12);
const years = sequenceArray(20, 40);

type PropTypes = {
  name: string;
};

export const CardExpiry = ({ name }: PropTypes) => {
  const {
    register,
    setValue,
    trigger,
    formState: { submitCount, errors },
  } = useFormContext();
  const [year, setYear] = useState<any>("");
  const [month, setMonth] = useState<any>("");

  const updateExpiry = () => {
    if (!year || !month) return;
    const cardExpiry = `${month}/${year}`;
    setValue(name, cardExpiry);
    submitCount > 0 && trigger(name);
  };

  useEffect(updateExpiry, [month, year]);

  return (
    <>
      {/* <Typography variant="subtitle2" className={styles.mainLabel}>
        Card Expiry
      </Typography> */}
      <input {...register(name, { required: "Please enter valid card expiry" })} type="hidden" />
      <Grid container>
        <Grid item xs={6}>
          <SelectField
            label="MM"
            value={month}
            options={months?.map((month) => (String(month).length < 2 ? `0${month}` : month))}
            onChange={(e) => {
              setMonth(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            label="YY"
            value={year}
            options={years}
            onChange={(e) => {
              setYear(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <ErrorMessage text={errors[name]?.message} />
    </>
  );
};
