import {
  Paper,
  Box,
  Typography,
  Divider,
  Grid,
  useTheme,
  useMediaQuery,
  makeStyles,
  colors,
  lighten,
} from "@material-ui/core";
import { UserCheck as UserCheckIcon } from "react-feather";
import { useConfig } from "src/context/ConfigContext";
import {
  RegisterAdditionalFields,
  FirstName,
  LastName,
  EmailAddress,
  PhoneNumber,
  SubmitButton,
  ConsentNotice,
} from "src/components/FormComponents";
import { useWizardCtx } from "../RegistrationFormWizard";

export const FormContainer = () => {
  const { config } = useConfig();
  const { submittingPersonal } = useWizardCtx();
  const teamId = config.TEAM_ID;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const styles: Record<string, any> = useStyles(config);

  return (
    <Paper variant="outlined">
      <Box px={xs ? 2 : 3} py={1} display="flex" justifyContent="flex-start" alignItems="center">
        <UserCheckIcon size={22} />
        <Typography variant="h5" className={styles.formTitle}>
          Contact Information
        </Typography>
      </Box>
      <Divider />
      <Box p={xs ? 3 : 4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FirstName name="firstName" />
          </Grid>
          <Grid item xs={12} md={6}>
            <LastName name="lastName" />
          </Grid>
          <Grid item xs={12}>
            <PhoneNumber name="phoneNumber" />
          </Grid>
          <Grid item xs={12}>
            <EmailAddress name="email" />
          </Grid>
          <Grid item xs={12}>
            <RegisterAdditionalFields />
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <SubmitButton text="NEXT" isLoading={submittingPersonal} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ConsentNotice text="By continuing you are agreeing to receive news and ticket offers from Mercedes-Benz Stadium." />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    formTitle: {
      fontSize: 22,
      fontFamily: "Poppins",
      fontWeight: 600,
      color: colors.grey[800],
      marginLeft: 10,
    },
    startIcon: {
      color: config?.COLORS.primary,
      fontSize: 20,
    },
    submitButton: {
      backgroundColor: config?.COLORS.primary,
      color: config?.COLORS.contrastText,
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 20,

      "&:hover": {
        backgroundColor: lighten(config?.COLORS.primary, 0.1),
      },
    },
  }))();
