import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  Container,
  fade,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";

const ThankYouPage = ({ config }) => {
  const styles = useStyles(config);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box className={styles.banner}>
      <Box className={styles.overlay} />
      <Container maxWidth={false} className={styles.container}>
        <Grid container justify="center">
          <Grid item xs={12} lg={6}>
            <Box p={xs ? 4 : 8} className={styles.content}>
              <Box>
                <Typography
                  variant="h1"
                  className={clsx(styles.title, "thankyou-title")}
                >
                  {config.PAGE_TITLES.thankYou.title}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography
                  variant="h2"
                  className={clsx(styles.subtitle, "thankyou-subtitle")}
                >
                  {config.PAGE_TITLES.thankYou.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const useStyles = (config) => {
  return makeStyles((theme) => ({
    banner: {
      height: "100vh",
      width: "100%",
      overflow: "hidden",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    overlay: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundImage: `url(${config?.HOME_HERO_BG})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: "105%",
      height: "105%",
      filter: "blur(8px)",
      "-webkit-filter": "blur(8px)",
    },
    container: {
      zIndex: 999,
    },
    content: {
      // position: "absolute",
      // top: "50%",
      // left: "50%",
      // transform: "translate(-50%, -50%)",
      // borderRadius: 15,
      overflow: "hidden",
      backgroundColor: "rgba(0,0,0,0.7)",
      border: "1px solid white",
    },

    title: {
      fontFamily: "Raleway",
      fontWeight: "700",
      fontSize: 64,
      color: "white",
      lineHeight: 1.2,
      [theme.breakpoints.down("xs")]: {
        fontSize: 42,
      },
    },
    subtitle: {
      fontFamily: "Raleway",
      fontWeight: 400,
      fontSize: 28,
      color: fade("#ffffff", 0.8),
      lineHeight: 1.4,
      [theme.breakpoints.down("xs")]: {
        fontSize: 24,
      },
    },
  }))();
};

export default ThankYouPage;
