import React from "react";
import {
  Box,
  Typography,
  Paper,
  makeStyles,
  ButtonGroup,
  Button,
  Divider,
} from "@material-ui/core";

export default function Product({ config, product, changeSeatCount }) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      borderRadius: 8,
      borderLeft: `3px solid ${config.COLORS.primary}`,
    },

    productWrapper: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 15px 1fr",
      alignItems: "center",

      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 1fr",
      },
    },
    title: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 18,
    },
    subtitle: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.2,
    },

    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },

    counter: {
      cursor: "default",
      pointerEvents: "none",
    },
  }));
  const classes = useStyles();
  const { ticketTypeName, currentSeats, price, eventTitle } = product;

  return (
    <Paper className={classes.paper} variant="outlined">
      <Box p={3} className={classes.productWrapper}>
        <Box>
          <Typography
            className={classes.title}
            variant="h6"
            color="textPrimary"
            gutterBottom
          >
            {ticketTypeName}
          </Typography>
          <Typography
            className={classes.subtitle}
            variant="subtitle1"
            color="textSecondary"
          >
            {eventTitle}
          </Typography>
        </Box>

        <Box className={classes.buttonWrapper}>
          <ButtonGroup>
            <Button
              variant="outlined"
              onClick={() => changeSeatCount({ increment: false, product })}
            >
              -
            </Button>
            <Button variant="outlined" className={classes.counter}>
              {currentSeats}
            </Button>
            <Button
              variant="outlined"
              onClick={() => changeSeatCount({ increment: true, product })}
            >
              +
            </Button>
          </ButtonGroup>
        </Box>
        <Divider orientation="vertical" />
        <Box className={classes.metaWrapper}>
          <Typography variant="subtitle2" color="textSecondary">
            ONE TIME COST : ${price}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            TOTAL AMOUNT : ${currentSeats * price}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}
