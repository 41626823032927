import React, { useState, createContext, useContext } from "react";
import {
  Box,
  Typography,
  Paper,
  makeStyles,
  Chip,
  colors,
  fade,
  Button,
  Divider,
} from "@material-ui/core";
import Popup from "src/components/Popup";
import { HighlightOff } from "@material-ui/icons";
import clsx from "clsx";
import { useHistory } from "react-router";
import { LocalMallOutlined } from "@material-ui/icons";

export default function PurchasePopup({
  config,
  popupOpen,
  successData,
  failedData,
  handleFinish,
}) {
  const styles = useStyles(config);
  const history = useHistory();

  return (
    <Popup
      open={popupOpen}
      // closeButtonProps={{
      //   canCloseByUser: true,
      //   backgroundColor: config.COLORS.primary,
      //   closeIcon: (
      //     <HighlightOff
      //       className={styles.closeButton}
      //       onClick={() => handlePopup(false)}
      //     />
      //   ),
      // }}
    >
      <Box p={1}>
        <Typography className={styles.popupTitle} variant="h4">
          <LocalMallOutlined className={styles.shoppingIcon} />
          Purchase Summary
        </Typography>
      </Box>
      <Divider />

      <Box p={2} pt={0}>
        <Box>
          {!!successData &&
            Object.keys(successData).map((groupId) => (
              <Event {...{ config, groupId }} products={successData[groupId]} />
            ))}
          {!!failedData &&
            Object.keys(failedData).map((groupId) => (
              <Event {...{ config, groupId }} products={failedData[groupId]} />
            ))}
        </Box>
      </Box>
      <Divider />
      <Box p={2} pt={1} pb={1} display="flex" justifyContent="flex-end">
        {/* <Box>
            <Button
              disableElevation
              variant="contained"
              size="medium"
              className={styles.cancelButton}
            >
              Retry
            </Button>
          </Box> */}

        <Button
          disableElevation
          variant="contained"
          fullWidth
          size="medium"
          className={styles.confirmButton}
          onClick={() => handleFinish()}
        >
          Finish
        </Button>
      </Box>
    </Popup>
  );
}

const Event = ({ config, groupId, products }) => {
  const styles = useStyles(config);

  return (
    <Box mt={2}>
      <Box>
        <Typography
          color="textPrimary"
          variant="h6"
          className={styles.eventTitle}
        >
          {groupId}
        </Typography>
      </Box>
      <Box>
        {products.length > 0 &&
          products.map((product) => <Product {...{ config, product }} />)}
      </Box>
    </Box>
  );
};

const Product = ({ config, product }) => {
  const styles = useStyles(config);
  return (
    <Box mt={1}>
      <Paper variant="outlined" className={styles.productPaper}>
        <Box p={1} pl={2} className={styles.productWrapper}>
          <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <Typography
              color="textPrimary"
              className={styles.productTitle}
              variant="h6"
            >
              {product.ticketTypeName}
            </Typography>
            <Typography
              color="textPrimary"
              className={styles.productMeta}
              variant="subtitle1"
            >
              {product.numSeats} Seats
            </Typography>

            {product.errorMessage && (
              <Typography
                color="textPrimary"
                className={styles.errorDesc}
                variant="subtitle1"
              >
                {product.errorMessage}
              </Typography>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            {!product.errorMessage ? (
              <MuiChip variant="success" {...{ config }} />
            ) : (
              <MuiChip variant="error" {...{ config }} />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const MuiChip = ({ variant, config }) => {
  const styles = useStyles(config);

  return (
    <Chip
      label={variant == "success" ? "SUCCESS" : "FAILED"}
      size="small"
      variant="outlined"
      className={variant == "success" ? styles.successChip : styles.errorChip}
    />
  );
};

export const usePurchasePopup = ({ config, claimPlanCode }) => {
  const history = useHistory();
  const [popupOpen, setPopupOpen] = useState(false);
  const [successData, setSuccessData] = useState(null);
  const [failedData, setFailedData] = useState(null);
  const handlePopup = (open) => setPopupOpen(open);
  const updatePopupData = (successData, failedData) => {
    setSuccessData(successData);
    setFailedData(failedData);
  };
  const handleFinish = () => {
    history.push(`/thank-you/${config.TEAM_ID}`);
  };
  return {
    popupOpen,
    successData,
    failedData,
    handlePopup,
    updatePopupData,
    handleFinish,
  };
};

const useStyles = (config) => {
  return makeStyles((theme) => ({
    popupTitle: {
      fontFamily: "Poppins",
      fontSize: 20,
      fontWeight: 500,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: colors.grey[700],
    },
    shoppingIcon: {
      marginRight: 5,
    },
    closeButton: {
      color: "white",
    },
    eventTitle: {
      fontFamily: "Poppins",
      fontSize: 18,
      fontWeight: 600,
    },
    productPaper: {
      borderRadius: 8,
      borderLeft: `2px solid ${config.COLORS.primary}`,
      borderRight: `2px solid ${config.COLORS.primary}`,
    },
    productWrapper: {
      display: "grid",
      gridTemplateColumns: "70% 1fr",
      alignItems: "center",
    },
    productTitle: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    productMeta: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 500,
      color: config.COLORS.primary,
    },
    errorDesc: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.1,
      color: colors.red[600],
    },
    successChip: {
      border: `1px solid ${colors.green[800]} !important`,
      backgroundColor: fade(colors.green[600], 0.2),
      color: colors.green[900],
      fontWeight: "bold",
      fontFamily: "poppins",
    },
    errorChip: {
      border: `1px solid ${colors.red[800]} !important`,
      backgroundColor: fade(colors.red[600], 0.2),
      color: colors.red[900],
      fontWeight: "bold",
      fontFamily: "poppins",
    },
    confirmButton: {
      backgroundColor: config.COLORS.primary,
      color: config.COLORS.contrastText,

      "&:hover": {
        backgroundColor: config.COLORS.secondary,
      },
    },
    cancelButton: {
      backgroundColor: colors.grey[600],
      color: config.COLORS.contrastText,

      "&:hover": {
        backgroundColor: colors.grey[400],
      },
    },
  }))();
};
