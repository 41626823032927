import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useConfig } from "src/context/ConfigContext";
import { useWizardCtx } from "../RegistrationFormWizard";
import { FormContainer } from "./FormContainer";

export const PaymentDetailsForm = () => {
  const { submitPaymentDetails } = useWizardCtx();
  // react hook form initialize
  const methods = useForm({ shouldFocusError: false, shouldUnregister: true });
  const { handleSubmit, setValue, reset } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitPaymentDetails)}>
        <FormContainer />
      </form>
    </FormProvider>
  );
};
