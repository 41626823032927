import React from "react";
import Total from "./Total";
import {
  Box,
  Paper,
  Button,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { ArrowBackIosOutlined } from "@material-ui/icons";

export default function BackPanel({ config, setCheckout, floating }) {
  const styles = useStyles(config);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Paper variant="outlined" className={floating ? styles.floating : ""}>
      <Box p={floating ? 2 : 3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Button
              className={styles.button}
              disableElevation
              variant="contained"
              size="medium"
              onClick={() => setCheckout(false)}
            >
              <ArrowBackIosOutlined className={styles.backIcon} />
              {xs ? "Tickets" : "Back to tickets"}
            </Button>
          </Box>
          <Box>
            <Total {...{ config }} />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = (config) => {
  return makeStyles({
    floating: {
      position: "fixed",
      top: 0,
      width: "100%",
      backgroundColor: "white",
      zIndex: 999,
      borderRadius: 0,
      border: "none",
      boxShadow: `0 0px 5.6px -13px rgba(0, 0, 0, 0.08),
  0 0px 45px -13px rgba(0, 0, 0, 0.16)`,
    },
    button: {
      backgroundColor: config.COLORS.primary,
      color: config.COLORS.contrastText,
      fontFamily: "Poppins",
      fontWeight: 400,

      "&:hover": {
        backgroundColor: config.COLORS.secondary,
      },
    },

    backIcon: {
      fontSize: 16,
      marginRight: 5,
    },
  })();
};
