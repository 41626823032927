import { RegisterConfig } from "src/types";
import * as yup from "yup";

export const blazers: RegisterConfig = {
  teamId: 77,
  ccEnabled: false,
  plan: {
    planName: "Blazers Last Minute Pass",
    description:
      "To register, simply connect your Team Account and provide your contact information to receive alerts for last-minute ticket offers.",
    tmTitle: "Connect Team Account",
    tmDescription:
      "Please connect your Team Account for ticket delivery. If you don’t have a Team Account you will be prompted to create a new one.",
  },
};
