import { RegisterConfig } from "src/types";
import * as yup from "yup";

export const iowa_state: RegisterConfig = {
  teamId: 189,
  ccEnabled: false,
  dotEduCheck: false,
  plan: {
    planName: "Iowa State Cyclone Last Minute Pass",
    description:
      "To register, simply connect your Iowa State Cyclone Team Account and provide your contact information to receive alerts for last-minute ticket offers.",
    tmTitle: "Connect Iowa State Cyclone Account",
    tmDescription:
      "Please connect your Iowa State Cyclone Team Account for ticket delivery. If you don’t have a Team Account you will be prompted to create a new one.",
  },

  additionalFields: [],
};
