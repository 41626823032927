import React from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  colors,
  Typography,
} from "@material-ui/core";
import { HighlightOffRounded } from "@material-ui/icons";

export default function ProductError() {
  const styles = useStyles();
  return (
    <Container>
      <Box mt={4} mb={24}>
        <Grid container spacing={4} justify="center">
          <Grid item xs={12} lg={7}>
            <Box>
              <Box textAlign="center">
                <HighlightOffRounded className={styles.errorIcon} />
              </Box>
              <Box textAlign="center">
                <Typography
                  className={styles.errorText}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  Claim window has ended
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

const useStyles = makeStyles({
  errorIcon: {
    fontSize: 36,
    color: colors.red[600],
  },
  errorText: {
    fontSize: 20,
    fontFamily: "Poppins",
  },
});
