import BannerImage from "src/images/hawks/hawks_new_banner_psd_cropped_nov_21.png";
import LogoImage from "src/images/hawks/hawks_logo_oct_21.png";
import CardImages from "src/images/cc-images.png";

const config = {
  SITE_TITLE: "ATLANTA HAWKS",
  TEAM_NAME: "Atlanta Hawks",
  TEAM_ID: 53,
  PRODUCT_NAME: "Claim Product",
  SITE_LOGO_URI: LogoImage,
  IS_DEVELOPMENT: false,
  REGISTRATION_ENABLED: true,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  CHECKOUT_TERMS_ENABLED: false,
  HEADER_ENABLED: true,
  CARD_ON_FILE: false,
  DOT_EDU_CHECK: false,
  CARD_ON_FILE_ERROR: false,

  // API base URLs
  API_BASE_URI: "https://gators-subs-dev.herokuapp.com/api",
  SS_FE_BASE_URI: "https://claim.seasonshare.com",
  ADMIN_BASE_URI: "https://subs-janitor-prod.herokuapp.com/api",
  ADMIN_URI:
    "https://claim-backend-multi-product-6bd3c222ac79282b.onporter.run",

  // ADMIN_BASE_URI: "http://localhost:32290/api",
  // ADMIN_URI: "http://localhost:3001",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#C8102E",
    secondary: "#FDB927",
    typography: "#130c0e",
    contrastText: "white",
    secondaryContrastText: "black",
    bannerOverlay: 0,
  },
  HOME_HERO_BG: BannerImage,
  CHECKOUT_CARD_IMAGES: CardImages,
  HOW_TO_IMAGE: "",

  PAGE_TITLES: {
    claimTicket: {
      title: "",
      subtitle: "",
    },
    thankYou: {
      title: "You're Good To Go!",
      subtitle:
        "We're excited to have you with us this season. You will receive a text message when your tickets are available in your Atlanta Hawks Team Account.",
    },
  },
  UI_LANGUAGES: {
    connectTeamAccount: {
      title: ``,
      description: `Hawks Pass is catered to college students looking to lock in last minute deals. Register to receive exclusive ticket offers throughout the entire season. You’ll get entry to State Farm Arena to enjoy an assortment of games and only pay once you accept the game offer. PLUS, if seats are available, we’ll automatically upgrade you for free!`,
      button: "LOGIN TO HAWKS ACCOUNT",
      disclaimer: `*Tickets are mobile-only and available through your Hawks Account and Hawks App. Tickets are standing-room only unless assigned to seating locations a few hours before tip-off.`,
    },
    checkout: {
      purchaseButtonText: "PURCHASE TICKETS",
      terms: `By purchasing you agree to receiving SMS alerts regarding ticket
            delivery.`,
    },
    registration: {
      description: `Hawks Pass is catered to college students looking to lock in last minute deals. Register to receive exclusive ticket offers throughout the entire season. You’ll get entry to State Farm Arena to enjoy an assortment of games and only pay once you accept the game offer. PLUS, if seats are available, we’ll automatically upgrade you for free!`,
    },
    registerSuccess: {
      title: "You’re Good To Go!",
      description:
        "You will receive text messages regarding last-minute ticket offers. You can opt out at any time by replying STOP to the offer. Standard text messaging rates apply.",
    },
  },
};

export default config;
