import React, { ReactElement } from "react";
import {
  Box,
  Paper,
  makeStyles,
  Typography,
  colors,
  darken,
  CircularProgress,
} from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";

export default function Preloader(): ReactElement {
  const { config } = useConfig();
  const styles = useStyles(config);

  return (
    <Paper variant="outlined" className={styles.paper}>
      <Box className={styles.planContent} p={2}>
        <Box>
          <CircularProgress
            size={24}
            style={{ color: config?.COLORS.primary }}
          />
        </Box>
        <Box textAlign="center" mt={1}>
          <Typography variant="h6" className={styles.planName}>
            Please wait...
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    paper: {
      padding: 8,
      height: "100%",
      boxSizing: "border-box",
      transition: "all 0.3s ease",
      "&:hover": {
        boxShadow: "0 5px 16px rgb(0 0 0 / 10%)",
      },
    },
    planContent: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      boxSizing: "border-box",
      minHeight: 150,
    },
    planName: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 18,
      color: colors.grey[800],
    },
  }))();
