import React, { useState } from "react";
import { Box, Typography, Paper, makeStyles, CircularProgress, TextField } from "@material-ui/core";
import PlacesAutocomplete, { geocodeByPlaceId } from "react-places-autocomplete";
import MatInput from "./MatInput";
import { v4 as uuid } from "uuid";

export default function AddressAutocomplete({ handleStreetChange, handleSuggestionOpen, config }) {
  const styles = useStyles(config);
  const [address, setAddress] = useState("");
  const handleChange = (address) => setAddress(address);

  const handleSelect = async (address, placeId) => {
    // Getting zip code
    const placeData = await geocodeByPlaceId(placeId);
    const place = placeData[0].address_components || [];
    const zip = place.find((component) => component.types.includes("postal_code"))?.long_name;

    // excluding city and country
    const splitted = address.split(",");
    if (splitted.length > 3) {
      const excluded = splitted.slice(0, splitted.length - 3);
      address = excluded.join();
    }

    zip ? handleStreetChange(address, zip) : handleStreetChange(address, "");
    handleSuggestionOpen(false);
  };

  const renderAddress = (item) => {
    let { matchedSubstrings: subStr, description } = item;
    let isMatched = subStr.length > 0 && subStr[0].offset === 0;
    let highlighted = isMatched ? description.slice(0, subStr[0].length) : "";
    let regular = isMatched ? description.slice(subStr[0].length) : "";
    return isMatched ? (
      <>
        <span className={styles.addressBold}>{highlighted}</span>
        <span className={styles.addressRegular}>{regular}</span>
      </>
    ) : (
      <span className={styles.addressRegular}>{item.description}</span>
    );
  };

  // direct google maps javascript sdk implementation
  const ref = React.useRef();
  let autocomplete;

  const onChange = () => {
    const place = autocomplete?.getPlace();
    const street = place.name;
    let zip;
    if (!place.geometry) return;
    for (const component of place?.address_components) {
      const componentType = component.types[0];
      if (componentType == "postal_code") {
        zip = component.long_name;
      }
    }

    if (street) handleStreetChange(street, zip ? zip : "");
    // if (zip) setValue("zip", zip);
    // console.log(zip);
  };

  const initAutocomplete = () => {
    if (!ref.current) return;
    let Autocomplete = window.google?.maps?.places?.Autocomplete;
    autocomplete = new Autocomplete(ref.current, {
      types: ["address"],
      fields: ["address_components", "geometry", "name"],
    });

    autocomplete?.addListener("place_changed", onChange);
  };

  React.useEffect(initAutocomplete, [ref]);

  return (
    <Paper elevation={8} className={styles.paper}>
      <Box p={2}>
        <TextField
          inputRef={ref}
          autoFocus
          size="small"
          className={styles.input}
          autoComplete="off"
          placeholder="Type your street address"
          onChange={() => {}}
          variant="outlined"
          fullWidth
          // endAdornment={
          //   loading && <CircularProgress size={14} style={{ color: config.COLORS.primary }} />
          // }
          // {...getInputProps()}
          // {...{ config }}
        />
        {/* <PlacesAutocomplete value={address} onSelect={handleSelect} onChange={handleChange}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <Box mt={1}>
              <MatInput
                autoFocus
                size="small"
                className={styles.input}
                autoComplete="off"
                placeholder="Type your street address"
                endAdornment={
                  loading && <CircularProgress size={14} style={{ color: config.COLORS.primary }} />
                }
                {...getInputProps()}
                {...{ config }}
              />
              <Box mt={1}>
                {suggestions.map((item) => (
                  <Box
                    className={styles.addressItem}
                    {...getSuggestionItemProps(item)}
                    key={uuid()}
                  >
                    <Typography className={styles.addressText}>{renderAddress(item)}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </PlacesAutocomplete> */}
      </Box>
    </Paper>
  );
}

const useStyles = (config) => {
  return makeStyles((theme) => ({
    paper: {
      borderRadius: 0,
      position: "absolute",
      width: "100%",
      top: "100%",
      zIndex: "99",
      backgroundColor: "#292A2D",
    },
    input: {
      "& .MuiFormLabel-root.Mui-focused": {
        color: config.COLORS.primary,
      },
      "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: config.COLORS.primary,
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: config.COLORS.primary,
      },

      "& input": {
        color: "white",
        border: "none",
        outline: "none",
      },

      "& fieldset": {
        border: "1px solid  gray",
        borderRadius: 8,
        // backgroundColor: "#1D1D1F",

        "&:hover": {
          borderColor: config.COLORS.primary,
        },
      },
    },
    addressItem: {
      padding: "10px 8px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid gray",
      minHeight: 30,
      transition: "all 0.3s ease",

      "&:last-child": {
        borderBottom: "none",
      },

      "&:hover": {
        backgroundColor: "gray",
      },
    },

    addressText: {
      color: "white",
      fontFamily: "Poppins",
      fontSize: 14,
    },

    addressBold: {
      fontWeight: 600,
    },
    addressRegular: {
      fontWeight: 200,
    },
  }))();
};
