import { InputField } from "./utils/InputField";
import { useFormContext } from "react-hook-form";
import { Map as MapIcon } from "react-feather";

type PropTypes = {
  name: string;
};

export const ZipCode = ({ name }: PropTypes) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputField
      {...register(name, { required: "Please insert a valid zip code." })}
      endAdornment={<MapIcon />}
      placeholder="Enter Zip Code"
      error={errors[name]?.message}
    />
  );
};
