import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Container,
  Grid,
  Typography,
  colors,
} from "@material-ui/core";
import { connectTeamAccount, login, sell } from "src/api/api";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { sample_cart_data } from "./sample_cart_data";
import { HowToImageViewer } from "./HowToImageViewer";

export default function ConnectTeamAccount({
  config,
  claimPlanCode,
  setUser,
  setLoggedIn,
  initCart,
  setCardDetails,
}) {
  const history = useHistory();
  const styles = useStyles(config);
  const [loading, setLoading] = useState(false);

  const loginIntoSystem = async (history, config) => {
    try {
      setLoading(true);
      const response = await login(claimPlanCode, history, config);
      const { user, products, cardDetails } = response.data;
      setCardDetails && setCardDetails(cardDetails);
      setUser(user);
      initCart(products);
      setLoggedIn(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      Swal.fire("Error!", "Error connecting team account, Please try again.", "error");
      history.push(`/claim-ticket/all/${config.TEAM_ID}/${claimPlanCode}`);
    }
  };

  useEffect(() => {
    let isCallBack = history.location.pathname.includes("tmcallback/login");
    console.log(isCallBack);
    isCallBack && loginIntoSystem(history, config);
  }, []);

  const handleConnectTeamAccount = async () => {
    try {
      const tmUrl = await connectTeamAccount(config, claimPlanCode, true);
      window.location.href = `${tmUrl}`;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Error connecting team account, Please try again."
      );
    }
  };

  return (
    <Container>
      <Box mt={6}>
        <Grid container justify="center" spacing={4}>
          {config.UI_LANGUAGES?.connectTeamAccount?.title && (
            <Grid item xs={12} lg={7}>
              <Box textAlign="center">
                <Typography variant="h5" className={styles.title}>
                  {config.UI_LANGUAGES?.connectTeamAccount?.title}
                </Typography>
              </Box>
            </Grid>
          )}

          {config.UI_LANGUAGES?.connectTeamAccount?.description && (
            <Grid item xs={12} lg={7}>
              <Box textAlign="center">
                <Typography variant="body1" className={styles.description}>
                  {config.UI_LANGUAGES?.connectTeamAccount?.description}
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} lg={7}>
            <Box>
              <Button
                fullWidth
                disableElevation
                size="large"
                disabled={loading}
                variant="contained"
                className={styles.button}
                onClick={handleConnectTeamAccount}
              >
                {config.UI_LANGUAGES?.connectTeamAccount?.button}
                {loading && (
                  <CircularProgress size={16} style={{ color: "white", marginLeft: 10 }} />
                )}
              </Button>
            </Box>
          </Grid>

          {config?.HOW_TO_IMAGE && (
            <Grid item xs={12}>
              <HowToImageViewer />
            </Grid>
          )}

          <Grid item xs={12} lg={7}>
            <Box textAlign="center">
              <Typography variant="caption" className={styles.disclaimer}>
                {config.UI_LANGUAGES?.connectTeamAccount?.disclaimer}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

const useStyles = (config) => {
  return makeStyles((theme) => ({
    title: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 24,
    },
    description: {
      fontFamily: "Poppins",
      color: colors.grey[600],
    },
    disclaimer: {
      lineHeight: 1.1,
      color: colors.grey[800],
      fontStyle: "italic",
      fontSize: 14,
    },
    button: {
      backgroundColor: config.COLORS.primary,
      color: "white",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 20,
      borderRadius: 40,

      "&:hover": {
        backgroundColor: config.COLORS.secondary,
        color: config.COLORS.secondaryContrastText,
      },

      "&.Mui-disabled": {
        backgroundColor: config.COLORS.primary,
        color: config.COLORS.contrastText,
      },
    },
  }))();
};
