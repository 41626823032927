import {
  Box,
  Grid,
  Select,
  FormControl,
  FormHelperText,
  makeStyles,
  colors,
  InputLabel,
  FormControlLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useForm, useFormContext, Controller } from "react-hook-form";
import { useConfig } from "src/context/ConfigContext";
import clsx from "clsx";
import { withStyles } from "@material-ui/styles";

// Helper fn
const sequenceArray = (start: number, end: number) => {
  const array: number[] = [];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
};
const months = sequenceArray(1, 12);
const years = sequenceArray(20, 40);

const SelectField = ({
  label,
  options,
  value,
  onChange,
}: {
  value?: any;
  label?: string;
  options?: any[];
  onChange?: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void | undefined;
}) => {
  const { config } = useConfig();
  const styles = useStyles(config);
  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      className={clsx(styles?.inputField, styles?.yearInput)}
    >
      <InputLabel className={styles.inputLabel}>{label}</InputLabel>
      <Select onChange={onChange} value={value}>
        {options?.map((value) => (
          <MenuItem value={value} key={uuid()}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ErrorMessage = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const StyledError = withStyles({
    root: {
      "&.MuiFormHelperText-root": {
        marginLeft: "14px",
        marginRight: "14px",
        marginTop: 0,
      },
    },
  })(FormHelperText);

  return (
    <Box
      style={{
        minHeight: "14px",
        backgroundColor: "transparent",
        lineHeight: "1",
      }}
    >
      {errors?.ccExp?.message && <StyledError error={true}>{errors?.ccExp?.message}</StyledError>}
    </Box>
  );
};

export const CardExpiry = () => {
  const { config } = useConfig();
  const styles = useStyles(config);
  const { register, setValue, trigger } = useFormContext();
  const [year, setYear] = useState<any>("");
  const [month, setMonth] = useState<any>("");

  const updateExpiry = () => {
    if (!year || !month) return;
    const cardExpiry = `${month}/${year}`;
    setValue("ccExp", cardExpiry);
    trigger("ccExp");
  };

  useEffect(updateExpiry, [month, year]);

  return (
    <>
      <Typography variant="subtitle2" className={styles.mainLabel}>
        Card Expiry
      </Typography>
      <input {...register("ccExp", { required: "Please enter valid card expiry" })} type="hidden" />
      <Grid container>
        <Grid item xs={6}>
          <SelectField
            label="MM"
            value={month}
            options={months?.map((month) => (String(month).length < 2 ? `0${month}` : month))}
            onChange={(e) => {
              setMonth(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            label="YY"
            value={year}
            options={years}
            onChange={(e) => {
              setYear(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <ErrorMessage />
    </>
  );
};

const useStyles = (config: any) =>
  makeStyles((theme) => ({
    mainLabel: {
      color: colors.grey[800],
      fontWeight: 600,
      fontFamily: "Poppins",
      marginBottom: 3,
    },
    inputLabel: {
      "&.MuiInputLabel-shrink": {
        backgroundColor: "white",
      },
    },
    inputField: {
      "& .MuiInputBase-root": {
        color: colors.grey[600],
      },

      "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: config.COLORS.primary,
      },
      "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F44336",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: config.COLORS.primary,
      },
      "& .MuiFormLabel-root.Mui-error": {
        color: "#F44336",
      },
    },

    monthInput: {
      "& fieldset": {
        // borderRightColor: "transparent",
        // borderTopRightRadius: 0,
        // borderBottomRightRadius: 0,
      },
    },

    yearInput: {
      "& fieldset": {
        // borderLeftColor: "transparent",
        // borderTopLeftRadius: 0,
        // borderBottomLeftRadius: 0,
      },
    },
  }))();
