/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import getConfig from "../../config/index";
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import ClaimTicket from "../../components/ClaimTicket/ClaimTicket";
import { connectTeamAccount, login, sellClaimTickets } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router";
import Swal from "sweetalert2";

const ClaimTicketContainer = ({ match }) => {
  const { teamId, eventId } = match?.params;
  const config = getConfig(teamId);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [products, setProducts] = useState([]);
  const [cartTotalAmount, setCartTotalAmount] = useState(0);
  const [cartTotalCount, setCartTotalCount] = useState(0);
  const history = useHistory();

  const updateCartTotal = (amount, count) => {
    setCartTotalAmount(amount);
    setCartTotalCount(count);
  };

  const sellClaimTickets = async (values) => {
    try {
      setLoading(true);
      values.products = products;
      values.teamId = teamId;
      
      const response = await sellClaimTickets(values, config);
      setLoading(false);
      history.push(`/thank-you/${teamId}`);
    } catch (err) {
      setLoading(false);
      Swal.fire(
        "Error!",
        err?.response?.data?.message || err?.message || "Something went wrong",
        "error"
      );
    }
  };
  const loginIntoSystem = async (history, config) => {
    try {
      setLoading(true);
      const response = await login(eventId, history, config);
      const { products, user } = response.data;
      setProducts(products);
      setUser(user);
      setLoggedIn(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Swal.fire("Error!", "Something went wrong. Please try again.", "error");
    }
  };

  useEffect(() => {
    let isCallBack = history.location.pathname.includes("tmcallback/login");
    console.log(isCallBack);
    isCallBack && loginIntoSystem(history, config);
  }, []);

  const handleConnectTeamAccount = async () => {
    try {
      const tmUrl = await connectTeamAccount(config, eventId);
      window.location.href = `${tmUrl}`;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
  };

  return (
    <div>
      <Header config={config} />
      <ToastContainer />
      <Banner config={config} />

      <ClaimTicket
        config={config}
        loggedIn={loggedIn}
        connectTeamAccount={handleConnectTeamAccount}
        user={user}
        products={products}
        cartTotalAmount={cartTotalAmount}
        cartTotalCount={cartTotalCount}
        updateCartTotal={updateCartTotal}
        sellClaimTickets={sellClaimTickets}
        loading={loading}
      />
    </div>
  );
};

export default ClaimTicketContainer;
