import { useConfig } from "src/context/ConfigContext";
import { useRegistrationConfig } from "src/registrationConfig";
import { CheckboxGroup } from "src/components/CheckboxGroup";
import { Input } from "src/components/Input";
import { useFormContext } from "react-hook-form";
import { Grid } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { FiInfo } from "react-icons/fi";

export const RegisterAdditionalFields = () => {
  const { config } = useConfig();
  const teamId = config?.TEAM_ID;
  const { additionalFields } = useRegistrationConfig(teamId);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {additionalFields?.map((field) => {
        if (field?.type === "checkbox") {
          return (
            <Grid item xs={12} key={uuid()}>
              <CheckboxGroup
                label={field?.label}
                options={field?.options}
                {...register(field?.name, field?.required ? { required: field?.required } : {})}
                error={errors[field?.name]?.message}
              />
            </Grid>
          );
        }
        if (field?.type === "input") {
          return (
            <Grid item xs={12} key={uuid()}>
              <Input
                label={field?.label}
                {...register(field?.name, field?.required ? { required: field?.required } : {})}
                placeholder={field?.placeholder}
                startIcon={<FiInfo />}
                error={errors[field?.name]?.message}
              />
            </Grid>
          );
        }
      })}
    </>
  );
};
