import React, { useEffect, useState } from "react";
import { Container, Box, Grid, useTheme, useMediaQuery, Paper } from "@material-ui/core";
import PaymentForm from "src/components/PaymentForm";
import BackPanel from "./BackPanel";

import { CheckoutFormV2 } from "src/components/CheckoutFormV2";

export default function CheckoutView({
  config,
  setCheckout,
  handlePurchase,
  email,
  firstName,
  lastName,
  phoneNumber,
  cardDetails,
}) {
  const [floating, setFloating] = useState(false);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const handleScroll = () => {
    window.onscroll = () => {
      let scrollY = window.scrollY;
      scrollY >= 440 ? setFloating(true) : setFloating(false);
    };
  };

  useEffect(handleScroll, []);

  return (
    <>
      <Container>
        <Box mt={4} mb={24}>
          <Grid container spacing={4} justify="center">
            <Grid item xs={12} lg={7}>
              <Box>
                <BackPanel {...{ config, setCheckout }} />
              </Box>
              <Box mt={2}>
                {/* <PaymentForm {...{ config, handlePurchase, email, cardDetails }} /> */}
                <Paper variant="outlined">
                  <Box p={3} pt={2}>
                    <CheckoutFormV2
                      {...{ handlePurchase, email, cardDetails, firstName, lastName, phoneNumber }}
                    />
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {xs && <BackPanel {...{ config, setCheckout, floating }} />}
    </>
  );
}
