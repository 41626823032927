import hawks from "./hawks";
import hawks_corporate from "./hawks_corporate";
import falcons from "./falcons";
import hawks_lastminute from "./hawks_lastminute";

const getConfig = (teamId) => {
  let config = {};
  switch (teamId) {
    case "53":
      config = hawks;
      break;

    // case "997":
    //   config = hawks_corporate;
    //   break;

    case "84":
      config = falcons;
      break;

    // case "101":
    //   config = hawks_lastminute;
    //   break;

    default:
      config = {};
  }
  return config;
};

export default getConfig;
