import { RegisterConfig } from "src/types";
import { hawks } from "./hawks";
import { hawks_corporate } from "./hawks_corporate";
import { blazers } from "./blazers";
import { knicks } from "./knicks";
import { rangers } from "./rangers";
import { kings } from "./kings";
import { diamondbacks } from "./diamondbacks";
import { jazz } from "./jazz";
import { falcons } from "./falcons";
import { iowa_state } from "./iowa_state";
import { sharks } from "./sharks";
import { bucks } from "./bucks";
import { bucks_junior } from "./bucks_junior";
import { sackings_student } from "./sackings_student";

const configs = [
  hawks,
  hawks_corporate,
  blazers,
  knicks,
  rangers,
  kings,
  diamondbacks,
  jazz,
  falcons,
  iowa_state,
  sharks,
  bucks,
  bucks_junior,
  sackings_student,
];

export const useRegistrationConfig = (teamId: number) => {
  return configs.find((c) => c.teamId === teamId) as RegisterConfig;
};
