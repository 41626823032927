import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";
import ImageViewer from "react-viewer";
import { TouchApp } from "@material-ui/icons";

export const HowToImageViewer = (): JSX.Element => {
  const { config } = useConfig();
  const [isViewing, setIsViewing] = useState<boolean>(false);
  const styles = useStyles(config);

  /** Handler for image viewer
   * @param isVieweing boolean
   */
  const viewImage = (isViewing: boolean) => setIsViewing(isViewing);

  /** Material ui div/Box component getting some extra height while having as child image.
   * This injectable fix will sync the box height with image height.
   */
  const injectHeightFix = () => {
    const image = document.getElementById("claim_how_to_image");
    const wrapper = document.getElementById("how_to_image_wrapper");
    const fixWrapperHeight = () => {
      const imageHeight = image?.offsetHeight;
      wrapper?.setAttribute("style", `height : ${imageHeight}px`);
    };

    window.addEventListener("load", fixWrapperHeight);
    window.addEventListener("resize", fixWrapperHeight);
  };

  useEffect(injectHeightFix, []);

  return (
    <Box mt={4} className={styles.imageWrapper} id="how_to_image_wrapper">
      <ImageViewer
        visible={isViewing}
        images={[{ src: config.HOW_TO_IMAGE }]}
        onClose={() => viewImage(false)}
        onMaskClick={() => viewImage(false)}
      />
      <Box className={styles.imageOverlay} onClick={() => viewImage(true)}>
        <Box>
          <TouchApp className={styles.imageViewIcon} />
        </Box>
        <Box>
          <Typography variant="caption" className={styles.imageViewCaption}>
            Tap to expand
          </Typography>
        </Box>
      </Box>
      <img
        id="claim_how_to_image"
        className={styles.howToImage}
        src={config?.HOW_TO_IMAGE}
        alt="How to claim"
      />
    </Box>
  );
};

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    imageWrapper: {
      position: "relative",
      overflow: "hidden",
      borderRadius: 16,
      boxShadow: `0px 0px 4.5px rgba(0, 0, 0, 0.031),
  0px 0px 10.9px rgba(0, 0, 0, 0.044),
  0px 0px 20.5px rgba(0, 0, 0, 0.055),
  0px 0px 36.6px rgba(0, 0, 0, 0.066),
  0px 0px 68.5px rgba(0, 0, 0, 0.079),
  0px 0px 164px rgba(0, 0, 0, 0.11)`,
    },
    imageOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 3,
      transition: "all 0.3s ease",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      cursor: "pointer",

      [theme.breakpoints.down("sm")]: {
        backgroundColor: "rgba(0,0,0,0.8)",
      },

      "& svg": {
        opacity: 0,

        [theme.breakpoints.down("sm")]: {
          opacity: 1,
        },
      },

      "& .MuiTypography-root": {
        opacity: 0,
        [theme.breakpoints.down("sm")]: {
          opacity: 1,
        },
      },

      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.5)",
        "& svg": {
          opacity: 1,
        },

        "& .MuiTypography-root": {
          opacity: 1,
        },
      },
    },

    imageViewIcon: {
      fontSize: 64,
      color: "white",
      transition: "all 0.3s ease",
    },

    imageViewCaption: {
      color: "white",
      fontSize: 18,
      fontFamily: "Poppins",
      fontWeight: 600,
    },

    howToImage: {
      width: "100%",

      // border: `1px solid ${theme.palette.divider}`,
    },
  }))();
