import { RegisterConfig } from "src/types";
import * as yup from "yup";

export const falcons: RegisterConfig = {
  teamId: 84,
  ccEnabled: true,
  dotEduCheck: true,
  formWizard: true,
  plan: {
    planName: "Mercedes-Benz Stadium College Pass",
    description:
      "To register, simply connect your Mercedes-Benz Stadium Account and provide your contact information to receive alerts for last-minute ticket offers.",
    tmTitle: "Connect Mercedes-Benz Stadium Account",
    tmDescription:
      "Please connect your Mercedes-Benz Stadium Account for ticket delivery. If you don’t have a Mercedes-Benz Stadium Account or your existing account is not tied to your official school’s email address (@.edu), you will be required to create a new one.",
  },

  additionalFields: [
    {
      name: "source", // consider providing a name without space
      type: "checkbox",
      label: "Where did you learn about this program?",
      required: "Please select one value.",
      schema: yup.string().required("Please select one value."),
      options: [
        "Auburn",
        "Emory",
        "Mercer",
        "Georgia Tech",
        "Georgia State",
        "Kennesaw State",
        "West Georgia",
        "Spelman",
        "Morehouse",
        "UGA",
        "Clayton State",
        "Agnes Scott",
        "Clark Atlanta",
        "Georgia Southern",
        "Oglethorpe University",
        "LaGrange",
        "University of Alabama",
        "Other",
      ],
    },
  ],
};
