import React, { useState, useEffect } from "react";
import { Box, Collapse, Paper } from "@material-ui/core";
import { usePaymentCtx, withFormik } from "./withFormik";
import PersonalDetails from "./PersonalDetails";
import BillingDetails from "./BillingDetails";
import CardDetails from "./CardDetails";
import SubmitButton from "./SubmitButton";
import ErrorChip from "./ErrorChip";
import AcceptNotice from "./AcceptNotice";
import { useCart } from "src/context/MultiCartContext";
import { CardOnFile } from "./CardOnFile";

export default withFormik(({ formik, config }) => {
  const { handleSubmit } = formik;
  const { ccOpen } = usePaymentCtx();
  const { products, grandTotal, totalSeats } = useCart();
  const cartHasAmount = Number(grandTotal) > 0;

  return (
    <Paper variant="outlined">
      <Box p={3} pt={2}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <PersonalDetails {...{ formik, config }} />
          {cartHasAmount && (
            <>
              <Box mt="24px">
                <CardOnFile />
              </Box>

              <Collapse in={ccOpen}>
                <BillingDetails {...{ formik, config }} />
                <CardDetails {...{ formik, config }} />
              </Collapse>
            </>
          )}

          <AcceptNotice {...{ config, formik }} />
          <ErrorChip {...{ formik, config }} />
          <SubmitButton {...{ formik, config }} />
        </form>
      </Box>
    </Paper>
  );
});
