import React, {
  createContext,
  useContext,
  ContextType,
  ReactElement,
  useState,
} from "react";

type ProviderPropType = {
  children: ReactElement;
};

type ConfigStateType = {
  config?: any;
  updateConfig?: Function;
};

const ConfigContext = createContext({});

export const ConfigProvider = ({
  children,
}: ProviderPropType): ReactElement => {
  const [config, setConfig] = useState({});
  const updateConfig = (config: any): void => {
    setConfig(config);
  };
  return (
    <ConfigContext.Provider value={{ config, updateConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = (): ConfigStateType => {
  return useContext(ConfigContext);
};
