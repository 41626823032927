import React from "react";
import {
  Grid,
  Box,
  Container,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import LoginNotice from "../LoginNotice/LoginNotice";
import ProductCart from "../ProductCart/ProductCart";
import CheckoutForm from "../CheckoutCart/CheckoutCart";

const ClaimTicket = ({
  config,
  connectTeamAccount,
  loggedIn,
  user,
  products,
  cartTotalAmount,
  cartTotalCount,
  updateCartTotal,
  sellClaimTickets,
  loading,
}) => {
  const useStyles = makeStyles(() => ({
    tmButton: {
      backgroundColor: config?.COLORS?.primary,
      color: "white",
      fontSize: 22,
      borderRadius: 40,
      paddingTop: 10,
      paddingBottom: 10,
      "&:hover": {
        backgroundColor: config?.COLORS?.secondary,
        color: config?.COLORS?.contrastText,
      },
    },
  }));
  const classes = useStyles();

  return (
    <Container style={{ marginBottom: "2rem" }}>
      <Grid container justify="center">
        {loggedIn ? (
          <React.Fragment>
            <Grid item xs={12} lg={8}>
              <Box pt={4}>
                <LoginNotice
                  accountUserName={user?.emailAddress}
                  config={config}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Box pt={4}>
                <ProductCart
                  {...{ config, products, cartTotalAmount, updateCartTotal }}
                />
              </Box>
            </Grid>
            {cartTotalCount > 0 && (
              <Grid item xs={12} lg={8}>
                <Box pt={4}>
                  <CheckoutForm
                    {...{
                      user,
                      cartTotalAmount,
                      config,
                      sellClaimTickets,
                      loading,
                    }}
                  />
                </Box>
              </Grid>
            )}
          </React.Fragment>
        ) : (
          <Grid item xs={12} lg={8}>
            <Box pt={4}>
              <Button
                variant="contained"
                disableElevation
                size="large"
                fullWidth
                className={classes.tmButton}
                onClick={connectTeamAccount}
              >
                Connect team account
                {loading && (
                  <CircularProgress
                    size={20}
                    style={{ marginLeft: 8, marginTop: -2, color: "white" }}
                  />
                )}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default ClaimTicket;
