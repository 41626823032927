import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Event from "./Event";
import { v4 as uuid } from "uuid";
import { useCart } from "src/context/MultiCartContext";

export default function MultiProductCart({ config }) {
  const { groupIds, products } = useCart();

  return (
    <>
      <Grid container spacing={4}>
        {groupIds.map((groupId) => {
          let productsArray = products.filter((p) => p.groupId == groupId);
          let productCount =
            productsArray && productsArray.length > 0
              ? productsArray.length
              : 0;
          let title =
            productsArray && productsArray.length > 0
              ? productsArray[0].eventTitle
              : groupId;

          const eventDate =
            productsArray &&
            productsArray.length > 0 &&
            productsArray[0].eventDate
              ? productsArray[0].eventDate
              : "";
          return (
            <React.Fragment key={uuid()}>
              {productCount > 0 && (
                <Grid item xs={12}>
                  <Event {...{ config, groupId, title, eventDate }} />
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
}
