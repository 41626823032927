import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("Please enter First Name")
    .min(2, "Please insert a valid first name"),
  lastName: yup
    .string()
    .required("Please enter Last Name")
    .min(2, "Please insert a valid last name"),
  email: yup
    .string()
    .email("Please insert a valid email address")
    .required("Please enter your email address."),
  phoneNumber: yup
    .string()
    .required("Please enter Phone Number")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number is not valid")
    .max(10, "Phone number is not valid"),

  termsAccepted: yup
    .boolean()
    .required("Please accept terms and conditions")
    .oneOf([true], "Please accept terms and conditions"),
});

export const creditCardSchema = {
  ccNum: yup
    .string()
    .required("Please enter Card Number")
    .min(17, "Please insert a valid card number")
    .max(20, "Please insert a valid card number"),

  ccExp: yup
    .string()
    .required("Please insert a valid card expiry")
    .min(4, "Please insert a valid card expiration date")
    .max(7, "Please insert a valid card expiration date"),
  streetAddress: yup
    .string()
    .required("Please enter a valid Street Address")
    .min(10, "Please insert a valid street address"),
  zipCode: yup.string().required("Please insert a valid zip code"),
};
