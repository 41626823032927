import { useEffect, createContext, useContext, useState } from "react";
import { useConfig } from "src/context/ConfigContext";
import { FormProvider, useForm } from "react-hook-form";
import { useCart } from "src/context/MultiCartContext";
import { CardDetails } from "src/types";
import { FormContainer } from "./FormContainer";

type PropTypes = {
  handlePurchase: Function;
  email: string;
  cardDetails: CardDetails;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
};

type CtxType = PropTypes & {
  toggleAddressType: () => void;
  isManualAddress: boolean;
  saveCCopen: boolean;
  toggleSaveCC: () => any;
  hasCCconfig: boolean;
  cartHasAmount: boolean;
  regularSubmit: any;
  cardOnFileSubmit: any;
};

const CheckoutCtx = createContext({});
export const useCheckoutCtx = () => useContext(CheckoutCtx) as CtxType;

export const CheckoutFormV2 = ({
  handlePurchase,
  email,
  cardDetails,
  firstName,
  lastName,
  phoneNumber,
}: PropTypes) => {
  const { config } = useConfig();
  const { grandTotal, products } = useCart();
  const cartHasAmount = Number(grandTotal) > 0;
  const hasCCconfig = config?.CARD_ON_FILE;

  // react hook form initialize
  const methods = useForm({ shouldFocusError: false, shouldUnregister: true });
  const { handleSubmit, setValue } = methods;

  // Manual address toggle states and handler
  const [isManualAddress, setIsManualAddress] = useState<boolean>(false);
  const toggleAddressType = () => {
    setValue("street", "");
    setIsManualAddress(!isManualAddress);
  };

  // save cc option states and handler
  const [saveCCopen, setSaveCCopen] = useState<boolean>(false);
  const toggleSaveCC = () => setSaveCCopen(!saveCCopen);

  // form submit handlers
  const regularSubmit = async (values: any) => {
    const { termsAgreed, ...finalValues } = values;
    await handlePurchase(finalValues, products);
  };

  const cardOnFileSubmit = async (values: any) => {
    const modifiedValues = { ...values };
    if (cartHasAmount && !saveCCopen && cardDetails?.last4Digits) {
      modifiedValues.ccSeqId = cardDetails?.ccSeqId;
      modifiedValues.ccType = cardDetails?.ccType;
    }
    if (cartHasAmount && (saveCCopen || !cardDetails?.last4Digits)) {
      modifiedValues.saveCard = true;
    }

    const { termsAgreed, ...finalValues } = modifiedValues;
    await handlePurchase(finalValues, products);
  };

  return (
    <CheckoutCtx.Provider
      value={{
        email,
        handlePurchase,
        toggleAddressType,
        isManualAddress,
        saveCCopen,
        toggleSaveCC,
        cardDetails,
        hasCCconfig,
        cartHasAmount,
        regularSubmit,
        cardOnFileSubmit,
        firstName,
        lastName,
        phoneNumber,
      }}
    >
      <FormProvider {...methods}>
        <FormContainer />
      </FormProvider>
    </CheckoutCtx.Provider>
  );
};
