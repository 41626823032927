import React from "react";
import { Box, Typography, makeStyles, colors } from "@material-ui/core";
import { LocalMallOutlined } from "@material-ui/icons";
import { useCart } from "src/context/MultiCartContext";

export default function Total({ config }) {
  const styles = useStyles();
  const { grandTotal } = useCart();

  return (
    <Box display="flex" alignItems="center">
      <LocalMallOutlined
        className={styles.cartIcon}
        style={{ color: config.COLORS.primary }}
      />
      <Typography variant="h5" className={styles.totalText}>
        TOTAL : <span className={styles.totalAmount}>${grandTotal}</span>
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  cartIcon: {
    color: colors.grey[700],
    marginRight: 8,
    fontSize: 28,

    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
      marginRight: 4,
    },
  },

  totalText: {
    fontFamily: "Raleway",
    fontSize: 12,
    fontWeight: 500,
    color: colors.grey[700],
    display: "flex",
    flexDirection: "column",
    lineHeight: 1,
  },
  totalAmount: {
    fontSize: 22,
    fontFamily: "Poppins",
    color: colors.grey[900],
    fontWeight: 600,

    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
}));
