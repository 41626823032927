import React from "react";
import { Button, CircularProgress, withStyles, colors } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { useConfig } from "src/context/ConfigContext";

const StyledButton = withStyles(() => {
  const { config } = useConfig();

  return {
    root: {
      backgroundColor: colors.grey[300],
      color: colors.grey[700],
      fontSize: 20,
      fontFamily: "Poppins",
      fontWeight: 600,

      "&:hover": {
        backgroundColor: colors.grey[200],
        color: colors.grey[700],
      },
    },
  };
})(Button);

type PropTypes = {
  text: string;
  isLoading?: boolean;
  onClick?: any;
};

export const CancelButton = ({ text, isLoading, onClick }: PropTypes) => {
  const { config } = useConfig();

  return (
    <StyledButton
      onClick={onClick}
      disabled={isLoading}
      variant="contained"
      size="large"
      fullWidth
      disableElevation
      startIcon={
        isLoading && (
          <CircularProgress size={14} style={{ color: config?.COLORS?.primary, marginLeft: 8 }} />
        )
      }
    >
      {text}
    </StyledButton>
  );
};
