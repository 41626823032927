import React, { useEffect, useMemo, useState } from "react";
import {
  Paper,
  Box,
  Grid,
  makeStyles,
  Typography,
  ButtonGroup,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Divider,
} from "@material-ui/core";
import clsx from "clsx";
import { useCart } from "src/context/MultiCartContext";
import {
  ReportProblemRounded,
  ReportProblemOutlined,
  ErrorOutlined,
  ErrorOutlineOutlined,
} from "@material-ui/icons";

export default function Product({ config, product }) {
  const styles = useStyles(config);
  const { changeSeatCount } = useCart();

  return (
    <Paper
      variant="outlined"
      className={styles.paper}
      style={{
        borderLeftColor: config.COLORS.primary,
        borderRightColor: config.COLORS.primary,
      }}
    >
      <Box p={3}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} sm={4} className={styles.gridItem}>
            <Box>
              <Typography className={styles.productName} variant="h6">
                {product.ticketTypeName}
              </Typography>
            </Box>

            <Box>
              {product.maxCount > 0 ? (
                <Typography className={styles.productMeta} variant="h6">
                  Max seat count : {product.maxCount}
                </Typography>
              ) : (
                <Typography className={styles.zeroSeatMeta} variant="h6">
                  <ErrorOutlineOutlined className={styles.warningIcon} /> Sold out or You have
                  claimed your limit for this event.
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3} className={clsx(styles.gridItem, styles.buttonGrid)}>
            <Box>
              <ButtonGroup disableRipple={true}>
                <Button onClick={() => changeSeatCount(product, false)}>-</Button>
                <Button className={styles.counter}>{product.numSeats}</Button>
                <Button onClick={() => changeSeatCount(product, true)}>+</Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} className={styles.gridItem}>
            <Box display="flex" alignItems="center">
              <Divider orientation="vertical" className={styles.divider} />
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={styles.tableCell}>Ticket Cost</TableCell>
                    <TableCell className={styles.tableCell}>:</TableCell>
                    <TableCell className={clsx(styles.tableCell, styles.amount)}>
                      ${product.price}
                    </TableCell>
                  </TableRow>

                  {product?.serviceFee > 0 && (
                    <TableRow>
                      <TableCell className={styles.tableCell}>Service Fee</TableCell>
                      <TableCell className={styles.tableCell}>:</TableCell>
                      <TableCell className={clsx(styles.tableCell, styles.amount)}>
                        ${product["serviceFee"]}
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell className={styles.tableCell}>Total Amount</TableCell>
                    <TableCell className={styles.tableCell}>:</TableCell>
                    <TableCell className={clsx(styles.tableCell, styles.amount)}>
                      ${product.total}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

const useStyles = (config) =>
  makeStyles((theme) => ({
    paper: {
      borderLeftWidth: 2,
      borderRightWidth: 2,
      borderRadius: 15,
      boxShadow: "0 8px 16px rgb(0 0 0 / 10%)",
      "&:hover": {
        boxShadow: "0 8px 16px rgb(0 0 0 / 10%)",
      },
    },

    gridItem: {
      [theme.breakpoints.down("xs")]: {
        padding: "5px 10px !important",
      },
    },

    buttonGrid: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    productName: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.3,
      color: colors.grey[700],
    },
    productMeta: {
      color: config.COLORS.primary,
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 600,
    },
    zeroSeatMeta: {
      color: "#f57c00",
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 600,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    warningIcon: {
      fontSize: 16,
      marginRight: 3,
    },
    counter: {
      cursor: "default",
      pointerEvents: "none",
      fontFamily: "Poppins",
      fontWeight: 600,
    },
    tableCell: {
      borderBottom: "none",
      padding: "0px 5px",
      fontFamily: "Poppins",
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 400,
      color: colors.grey[700],
    },
    amount: {
      fontWeight: 600,
      color: colors.grey[900],
    },
    divider: {
      height: 50,
      marginRight: 15,

      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  }))();
