import React, { useEffect } from "react";
import { Box, makeStyles, fade, Checkbox, withStyles, FormHelperText } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DoneAllRounded } from "@material-ui/icons";
import { useConfig } from "src/context/ConfigContext";
import { useFormContext } from "react-hook-form";

const ErrorMessage = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const StyledError = withStyles({
    root: {
      "&.MuiFormHelperText-root": {
        marginLeft: "14px",
        marginRight: "14px",
        marginTop: 0,
        lineHeight: 1.3,
      },
    },
  })(FormHelperText);

  return (
    <Box
      style={{
        minHeight: "14px",
        backgroundColor: "transparent",
        lineHeight: "1",
      }}
    >
      {errors?.termsAgreed?.message && (
        <StyledError error={true}>{errors?.termsAgreed?.message}</StyledError>
      )}
    </Box>
  );
};

export const AcceptNotice = () => {
  const {
    register,
    formState: { errors, isValid },
  } = useFormContext();
  const { config } = useConfig();
  const styles = useStyles(config);
  const termsLanguage = config?.UI_LANGUAGES?.checkout?.terms;

  return (
    <>
      {
        <Box mt={2}>
          <Alert
            variant="standard"
            severity="success"
            icon={
              config?.CHECKOUT_TERMS_ENABLED ? (
                <Checkbox
                  {...register("termsAgreed", {
                    required: "Please accept our terms and conditions to complete your purchase.",
                  })}
                />
              ) : (
                <DoneAllRounded />
              )
            }
            classes={{
              root: styles.background,
              message: styles.message,
              icon: styles.icon,
            }}
          >
            {termsLanguage && <span>{termsLanguage}</span>}
          </Alert>
        </Box>
      }
      <ErrorMessage />
    </>
  );
};

const useStyles = (config: any) => {
  return makeStyles((theme) => ({
    background: {
      backgroundColor: fade(config.COLORS.primary, 0.06),
    },

    message: {
      color: config.COLORS.primary,
      display: "flex",
      alignItems: "center",
    },
    icon: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      width: 30,
      "& svg": {
        color: config.COLORS.primary,
      },
    },
    errorMessage: {
      color: "#F44336",
      lineHeight: 1.2,
      fontSize: "12px",
      // fontFamily: "Roboto, sans-serif",
    },
  }))();
};
