import { Grid, Typography, Box, makeStyles, colors, IconButton } from "@material-ui/core";
import { Input } from "src/components/Input";
import { useFormContext } from "react-hook-form";
import { AiOutlineCreditCard } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";
import { formatCreditCardNumber, formatExpirationDate } from "src/utils/utils";
import { ChangeEvent } from "react";
import { useConfig } from "src/context/ConfigContext";
import CcImage from "src/images/cc-images.png";
import { AddressAutocomplete } from "./AddressAutocomplete";
import { CardExpiry } from "./CardExpiry";
import { StreetAutocomplete } from "./StreetAutocomplete";
import { XCircle as CloseIcon, MapPin as MapIcon } from "react-feather";
import { IoLocationOutline } from "react-icons/io5";

export const PaymentDetails = () => {
  const { config } = useConfig();
  const styles: Record<string, any> = useStyles(config);
  const {
    register,
    setValue,
    formState: { errors, submitCount },
    trigger,
    watch,
  } = useFormContext();

  const handleCardInput = (event: any) => {
    const value = event.target.value;
    const formatted = formatCreditCardNumber(value);
    setValue("ccNum", formatted, { shouldValidate: true });
  };

  const handleExpiryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const formatted = formatExpirationDate(value);
    setValue("ccExp", formatted);
  };

  const handleAddressChange = (address: { street?: string; zip?: string }) => {
    const { street, zip } = address;
    setValue("streetAddress", street ? street : "");
    setValue("zipCode", zip ? zip : "");
    submitCount > 0 && trigger("streetAddress");
    submitCount > 0 && trigger("zipCode");
  };

  const clearAddress = (event: any) => {
    event.stopPropagation();
    setValue("streetAddress", "");
    setValue("zipCode", "");
    submitCount > 0 && trigger("streetAddress");
    submitCount > 0 && trigger("zipCode");
  };

  return (
    <>
      <Grid item xs={12} style={{ paddingBottom: 0, marginTop: "10px" }}>
        <Box display={"flex"} justifyContent="space-between" alignItems={"center"}>
          <Typography variant="subtitle2" className={styles.sectionTitle}>
            Payment Details
          </Typography>
          <img style={{ width: "auto", height: "28px" }} src={CcImage} alt="" />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Input
          {...register("ccNum", {
            required: "Please enter Card Number",
            minLength: { value: 17, message: "Please enter 15/16 digit card number." },
            maxLength: { value: 20, message: "Please enter 15/16 digit card number." },
          })}
          onChange={handleCardInput}
          startIcon={<AiOutlineCreditCard />}
          label="Card Number"
          placeholder="Enter Card Number"
          error={errors?.ccNum?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <CardExpiry />
        {/* <Input
          {...register("ccExp")}
          onChange={handleExpiryChange}
          startIcon={<MdDateRange />}
          label="Card Expiry"
          placeholder="MM/YY"
          error={errors?.cardExpiry?.message}
        /> */}
      </Grid>
      <Grid item xs={12} lg={6}>
        <StreetAutocomplete onChange={handleAddressChange}>
          <Input
            {...register("streetAddress", {
              required: "Please enter your street",
            })}
            label="Street Address"
            role={"presentation"}
            // label="Street Address"
            placeholder="Street Address"
            error={errors?.streetAddress?.message}
            startIcon={<IoLocationOutline />}
            endIcon={
              watch("streetAddress") && (
                <IconButton size={"small"} onClick={clearAddress}>
                  <CloseIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                </IconButton>
              )
            }
          />
        </StreetAutocomplete>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Input
          {...register("zipCode", { required: "Please insert a valid zip code." })}
          startIcon={<AiOutlineCreditCard />}
          label="Zip code"
          placeholder="Enter Zip Code"
          error={errors?.zipCode?.message}
        />
      </Grid>
    </>
  );
};

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    sectionTitle: {
      color: colors.grey[800],
      fontWeight: 600,
      fontFamily: "Poppins",
      marginBottom: 3,
      fontSize: "20px",
    },
  }))();
