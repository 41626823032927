import React, { useEffect } from "react";
import { Box, makeStyles, fade, Checkbox } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DoneAllRounded } from "@material-ui/icons";

export default function AcceptNotice({ config, formik }) {
  const styles = useStyles(config);
  const termsLanguage = config?.UI_LANGUAGES?.checkout?.terms;
  const errorMessage = "Please accept our terms and conditions to complete your purchase.";
  const { setFieldValue, values, submitCount, setFieldError, errors } = formik;

  useEffect(() => {
    if (formik.submitCount > 0 && !values.termsAgreed) {
      setFieldError("termsAgreed", errorMessage);
    }
  }, [submitCount]);

  useEffect(() => {
    // If terms and condition is not enabled from
    // config, then setting the termsAgreed true by default
    const termsEnabled = config?.CHECKOUT_TERMS_ENABLED;
    if (!termsEnabled) setFieldValue("termsAgreed", true);
  }, [config, values]);

  const handleChange = (event) => {
    event.persist();
    let checked = event.target.checked;
    setFieldValue("termsAgreed", checked);
  };

  return (
    <>
      {
        <Box mt={2}>
          <Alert
            variant="standard"
            severity="success"
            icon={
              config?.CHECKOUT_TERMS_ENABLED ? (
                <Checkbox checked={values.termsAgreed} onChange={handleChange} />
              ) : (
                <DoneAllRounded />
              )
            }
            classes={{
              root: styles.background,
              message: styles.message,
              icon: styles.icon,
            }}
          >
            {termsLanguage && <span>{termsLanguage}</span>}
          </Alert>
        </Box>
      }
      {errors["termsAgreed"] && submitCount > 0 && (
        <Box mt={1} className={styles.errorMessage}>
          {errors["termsAgreed"]}
        </Box>
      )}
    </>
  );
}

const useStyles = (config) => {
  return makeStyles((theme) => ({
    background: {
      backgroundColor: fade(config.COLORS.primary, 0.06),
    },

    message: {
      color: config.COLORS.primary,
      display: "flex",
      alignItems: "center",
    },
    icon: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      width: 30,
      "& svg": {
        color: config.COLORS.primary,
      },
    },
    errorMessage: {
      color: "#F44336",
      lineHeight: 1.2,
    },
  }))();
};
