import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

export default function Preloader({ config }) {
  return (
    <Box textAlign="center" p={4} mt={8}>
      <CircularProgress size={24} style={{ color: config.COLORS.primary }} />{" "}
    </Box>
  );
}
