import React, { createContext, useState, useContext, useEffect } from "react";
import { useConfig } from "src/context/ConfigContext";

const MultiCartContext = createContext();

export const MultiCartProvider = ({ children }) => {
  const { config } = useConfig();
  const [products, setProducts] = useState([]);
  const [groupIds, setGroupIds] = useState([]);
  const [groupTotals, setGroupTotals] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalSeats, setTotalSeats] = useState(0);

  const resetProduct = (product, groupId) => {
    product.groupId = groupId;
    product.price = Number(product.price).toFixed(2);
    product.serviceFee = product?.serviceFee ? Number(product.serviceFee) : 0;
    // product.serviceFee = Number(product.serviceFee).toFixed(2);
    product.seats = [...Array(Number(product.maxCount) + 1).keys()];
    // Hard coded for magic
    if (config.TEAM_ID == 74 && product.maxCount >= 2) {
      let seats = [...product.seats];
      let filtered = seats.filter((s) => s > 2);
      let modified = [0, 2, ...filtered];
      product.seats = modified;
    }
    product.numSeats = product.seats[0];
    product.total = (0).toFixed(2);
    return product;
  };

  const resetGroup = (groupId) => {
    let group = products.filter((p) => p.groupId == groupId);
    for (let product of group) {
      let index = products.indexOf(product);
      let groupId = product.groupId;
      resetProduct(product, groupId);
      products[index] = product;
    }
    setProducts([...products]);
  };

  const flattenProducts = (data) => {
    let products = [];
    for (let groupId in data) {
      for (let product of data[groupId]) {
        resetProduct(product, groupId);
        products.push(product);
      }
    }
    return products;
  };

  const initCart = (data) => {
    let products = flattenProducts(data);
    let groupIds = Object.keys(data);
    setGroupIds(groupIds);
    setProducts(products);
  };

  const changeSeatCount = (product, increment) => {
    let seats = product.seats;
    let currentIndex = seats.indexOf(product.numSeats);
    let next = currentIndex + 1;
    let previous = currentIndex - 1;
    if (!increment && previous < 0) return;
    if (increment && next > seats.length - 1) return;
    let select = (index) => (product.numSeats = seats[index]);
    increment ? select(next) : select(previous);

    // calculate product total
    product.total = (product.price * product.numSeats).toFixed(2);

    // add service fee
    let seatCount = product.numSeats;
    let serviceFee = Number(product.serviceFee);
    let total = Number(product.total);
    let withFee = (total + serviceFee * seatCount).toFixed(2);
    seatCount > 0 && (product.total = withFee);

    // updating state
    setProducts([...products]);
  };

  const calculateTotals = () => {
    if (!(products.length > 0)) return;
    // Calculating group wise totals and
    // Total seat count
    let groupTotals = [];
    let totalSeats = 0;
    groupIds.forEach((groupId) => {
      let total = 0;
      products
        .filter((p) => p.groupId == groupId)
        .map((product) => {
          total += Number(product.total);
          totalSeats += product.numSeats;
        });
      groupTotals.push({
        groupId,
        total: total.toFixed(2),
      });
    });
    // Calculating grandTotal
    let grandTotal = 0;
    groupTotals.forEach((group) => {
      let total = Number(group.total);
      grandTotal += total;
    });
    // Setting states with totals
    setGroupTotals(groupTotals);
    setGrandTotal(grandTotal.toFixed(2));
    setTotalSeats(totalSeats);
  };

  const getGroupTotal = (groupId) => {
    return groupTotals.find((group) => group.groupId == groupId)?.total || 0;
  };

  useEffect(calculateTotals, [products]);

  return (
    <MultiCartContext.Provider
      value={{
        products,
        groupIds,
        getGroupTotal,
        grandTotal,
        initCart,
        changeSeatCount,
        totalSeats,
        resetGroup,
      }}
    >
      {children}
    </MultiCartContext.Provider>
  );
};

export function useCart() {
  const cart = useContext(MultiCartContext);
  return {
    ...cart,
  };
}
