import { RegisterConfig } from "src/types";
import * as yup from "yup";

export const jazz: RegisterConfig = {
  teamId: 81,
  ccEnabled: false,
  plan: {
    planName: "Utah Jazz Last Minute Pass",
    description:
      "To register, simply connect your Utah Jazz Team Account and provide your contact information to receive alerts for last-minute ticket offers.",
    tmTitle: "Connect Utah Jazz Team Account",
    tmDescription:
      "Please connect your Utah Jazz Team Account for ticket delivery. If you don’t have an Account you will be prompted to create a new one.",
  },
};
