import { InputField } from "./utils/InputField";
import { useFormContext } from "react-hook-form";
import { User as UserIcon, Smartphone as PhoneIcon, Mail as MailIcon } from "react-feather";

type PropTypes = {
  name: string;
};

export const LastName = ({ name }: PropTypes) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <InputField
      placeholder="Last Name"
      error={errors[name]?.message}
      endAdornment={<UserIcon />}
      {...register(name, {
        required: "Please enter Last Name.",
      })}
    />
  );
};
