import { AiOutlineCreditCard } from "react-icons/ai";
import { InputField } from "./utils/InputField";
import { useFormContext } from "react-hook-form";
import { formatCreditCardNumber } from "src/utils/utils";
import { UserCheck as UserCheckIcon, CreditCard as CreditCardIcon } from "react-feather";

type PropTypes = {
  name: string;
};

export const CardInput = ({ name }: PropTypes) => {
  const {
    register,
    formState: { errors, submitCount },
    setValue,
    trigger,
  } = useFormContext();

  const handleCardInput = (event: any) => {
    const value = event.target.value;
    const formatted = formatCreditCardNumber(value);
    setValue(name, formatted);
    submitCount > 0 && trigger(name);
  };

  return (
    <InputField
      {...register(name, {
        required: "Please enter Card Number",
        minLength: { value: 17, message: "Please enter 15/16 digit card number." },
        maxLength: { value: 20, message: "Please enter 15/16 digit card number." },
      })}
      onChange={handleCardInput}
      endAdornment={<CreditCardIcon />}
      //   label="Card Number"
      placeholder="Enter Card Number"
      error={errors[name]?.message}
    />
  );
};
