import React from "react";
import { Button, Grid, makeStyles, Box, CircularProgress, withStyles } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { useCart } from "src/context/MultiCartContext";
import { useConfig } from "src/context/ConfigContext";

const StyledButton = withStyles(() => {
  const { config } = useConfig();

  return {
    root: {
      backgroundColor: config?.COLORS?.primary,
      color: config?.COLORS?.contrastText,
      fontSize: 20,
      fontFamily: "Poppins",
      fontWeight: 600,

      "&:hover": {
        backgroundColor: config.COLORS.secondary,
        color: config.COLORS.secondaryContrastText,
      },
    },
  };
})(Button);

export const Submit = () => {
  const { config } = useConfig();
  const { grandTotal } = useCart();
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box mt={2}>
          <StyledButton
            type="submit"
            disabled={isSubmitting}
            variant="contained"
            size="large"
            fullWidth
            disableElevation
            startIcon={
              isSubmitting && (
                <CircularProgress
                  size={14}
                  style={{ color: config?.COLORS?.primary, marginLeft: 8 }}
                />
              )
            }
          >
            {grandTotal > 0 ? config?.UI_LANGUAGES?.checkout?.purchaseButtonText : "Claim Tickets"}
          </StyledButton>
        </Box>
      </Grid>
    </Grid>
  );
};
