import { PersonalDetails } from "./PersonalDetails";
import { Submit } from "./Submit";
import { BillingDetails } from "./BillingDetails";
import { PaymentDetails } from "./PaymentDetails";
import { AcceptNotice } from "./AcceptNotice";
import { useCart } from "src/context/MultiCartContext";
import { ErrorChip } from "./ErrorChip";
import { CardDetails } from "src/types";
import { Collapse } from "@material-ui/core";
import { CardOnFile } from "./CardOnFile";
import { useFormContext } from "react-hook-form";
import { useCheckoutCtx } from "./CheckoutFormV2";
import { Box } from "@material-ui/core";

const RegularForm = () => {
  const { handleSubmit } = useFormContext();
  const { regularSubmit, cartHasAmount } = useCheckoutCtx();

  return (
    <form onSubmit={handleSubmit(regularSubmit)}>
      <Box>
        <PersonalDetails />
      </Box>
      {cartHasAmount && (
        <>
          <Box mt="4">
            <BillingDetails />
          </Box>
          <Box mt="4">
            <PaymentDetails />
          </Box>
        </>
      )}

      <AcceptNotice />
      <ErrorChip />
      <Submit />
    </form>
  );
};

const CardOnFileForm = () => {
  const { handleSubmit } = useFormContext();
  const { cartHasAmount, cardDetails, saveCCopen, cardOnFileSubmit } = useCheckoutCtx();

  return (
    <form onSubmit={handleSubmit(cardOnFileSubmit)}>
      <Box>
        <PersonalDetails />
      </Box>
      {cartHasAmount && (
        <>
          {cardDetails?.last4Digits && (
            <Box mt="16px">
              <CardOnFile />
            </Box>
          )}

          <Collapse in={saveCCopen}>
            {saveCCopen && (
              <>
                <Box mt="16px">
                  <BillingDetails />
                </Box>
                <Box mt="16px">
                  <PaymentDetails />
                </Box>
              </>
            )}
          </Collapse>

          {!cardDetails?.last4Digits && (
            <>
              <Box mt="16px">
                <BillingDetails />
              </Box>
              <Box mt="16px">
                <PaymentDetails />
              </Box>
            </>
          )}
        </>
      )}

      <AcceptNotice />
      <ErrorChip />
      <Submit />
    </form>
  );
};

export const FormContainer = () => {
  const { hasCCconfig } = useCheckoutCtx();

  return (
    <>
      {hasCCconfig && <CardOnFileForm />}
      {!hasCCconfig && <RegularForm />}
    </>
  );
};
