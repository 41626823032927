import React, { ReactElement, ReactEventHandler, BaseSyntheticEvent } from "react";
import { Checkbox, makeStyles, fade, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useConfig } from "src/context/ConfigContext";
import { useFormContext } from "react-hook-form";
import { Grid } from "@material-ui/core";

type PropTypes = {
  selected: boolean;
  error?: string;
  onChange: Function;
  name: string;
};

export default function AcceptNotice(): ReactElement {
  const { config } = useConfig();
  const styles = useStyles(config);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // const handleSelect = (event: BaseSyntheticEvent) => {
  //   const checked = event.target.checked;
  //   const maskedEvent = { target: { name, value: !selected } };
  //   onChange(maskedEvent);
  // };

  return (
    <Grid item xs={12}>
      <Alert
        variant="standard"
        severity="success"
        icon={
          <Checkbox
            disableRipple
            disableFocusRipple
            className={styles.alertCheckbox}
            // checked={selected || false}
            // onChange={handleSelect}
            {...register("termsAccepted", { required: "Please accept terms and conditions." })}
          />
        }
        classes={{
          root: styles.alertBackground,
          message: styles.alertMessage,
          icon: styles.alertIcon,
        }}
      >
        By registering you agree to receive SMS alerts regarding ticket offers and delivery along
        with the program{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://uploads-ssl.webflow.com/5ed7df6fe67e862e0a62f9f2/616882d2b691e09f75348396_Terms%20%26%20Conditions.pdf"
          className={styles.terms_link}
        >
          Terms and Conditions
        </a>{" "}
        +{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://uploads-ssl.webflow.com/5ed7df6fe67e862e0a62f9f2/60c0e3b4ffe34bae9ba7d619_Season%20Share%20Privacy%20Policy.pdf"
          className={styles.terms_link}
        >
          Privacy Policy
        </a>
      </Alert>
      <Typography variant="caption" className={styles.errorText}>
        {errors?.termsAccepted?.message}
      </Typography>
    </Grid>
  );
}

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    errorText: {
      color: theme.palette.error.main,
    },
    alertBackground: {
      backgroundColor: fade(config.COLORS.primary, 0.06),
    },
    alertMessage: {
      color: config.COLORS.primary,
    },
    alertIcon: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      "& svg": {
        color: config.COLORS.primary,
      },
    },

    alertCheckbox: {
      padding: 0,
      width: "fit-content",
      height: "fit-content",
      // "& .MuiSvgIcon-root": {
      //   color: colors.grey[400],
      // },
      // "&.Mui-checked .MuiSvgIcon-root": {
      //   color: config?.COLORS.primary,
      // },
    },

    terms_link: {
      color: "blue",
    },
  }))();
