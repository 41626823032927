import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  makeStyles,
  Divider,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { ShoppingBag as CartIcon } from "react-feather";
import Product from "./Product";
import { v4 as uuid } from "uuid";

export default function ProductCart({ config, products, updateCartTotal }) {
  const classes = useStyles();
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  // Updating cart total amount via context
  useEffect(() => {
    updateCartTotal(cartTotal, cartCount);
  }, [cartTotal, cartCount]);

  // Formatting the seat permutations from string to array
  // to store them in reusable state
  useEffect(() => {
    products.map((product) => {
      let seatPermutation = [];
      for (let i = 0; i <= product.max_count; i++) {
        seatPermutation.push(i);
      }
      let currentSeats = seatPermutation[0];
      Object.assign(product, {
        seatPermutation,
        currentSeats,
      });
      return product;
    });

    setCartItems(products);
  }, [products]);

  // getting cart total amount to update the
  // context provider
  useEffect(() => {
    if (cartItems.length > 0) {
      let total = 0;
      let count = 0;
      for (let item of cartItems) {
        let amount = item.currentSeats * item.price;
        total += amount;
        count += item.currentSeats;
      }
      setCartTotal(total);
      setCartCount(count);
    }
    console.log(cartItems);
  }, [cartItems]);

  const changeSeatCount = ({ increment, product }) => {
    let item = cartItems.find((item) => item === product);
    let itemIndex = cartItems.indexOf(item);
    let { seatPermutation } = item;
    let currentSeatIndex = seatPermutation.indexOf(item.currentSeats);

    if (
      (currentSeatIndex === 0 && !increment) ||
      (currentSeatIndex === seatPermutation.length - 1 && increment)
    )
      return;

    if (increment) {
      item.currentSeats = seatPermutation[currentSeatIndex + 1];
    } else {
      item.currentSeats = seatPermutation[currentSeatIndex - 1];
    }

    cartItems.splice(itemIndex, 1, item);
    setCartItems([...cartItems]);
  };

  return (
    <Paper variant="outlined">
      <Box
        p={1}
        pl={2}
        pr={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <CartIcon size={24} />
          <Typography
            variant="h5"
            className={classes.cardTitle}
            color="textPrimary"
          >
            Purchase Tickets
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box p={xs ? 2 : 4}>
        <Grid container spacing={3}>
          {/* all products visible in the cart */}
          {cartItems.length > 0 &&
            cartItems.map((product) => (
              <Grid item xs={12} key={uuid()}>
                <Product {...{ config, product, changeSeatCount }} />
              </Grid>
            ))}
        </Grid>
      </Box>

      <Divider />
      <Box
        p={1}
        pl={4}
        pr={4}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="h6"
            className={classes.cardFooterText}
            color="textPrimary"
          >
            TOTAL : ${cartTotal}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  cardTitle: {
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: 24,
    marginLeft: 15,

    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  cardFooterText: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 16,
  },
}));
