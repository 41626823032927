import React, { forwardRef, ReactNode } from "react";
import { TextField, withStyles, Box } from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";

type PropTypes = {
  placeholder: string;
  error?: string;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  // rest props
  [k: string]: any;
};

const StyledInput = withStyles(() => {
  const { config } = useConfig();

  return {
    root: {
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: config?.COLORS?.primary,
        },

        "&.MuiOutlinedInput-adornedEnd svg": {
          color: "#a0a0a0",
        },
      },
      "& .MuiFormHelperText-root": {
        //
        marginTop: "0",
      },
    },
  };
})(TextField);

const StyledError = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      style={{
        minHeight: "14px",
        backgroundColor: "transparent",
        lineHeight: "1",
        marginTop: "2px",
      }}
    >
      {children}
    </Box>
  );
};

export const InputField = forwardRef(
  (
    { placeholder, error, endAdornment, startAdornment, ...restProps }: PropTypes,
    ref: any
  ): JSX.Element => {
    return (
      <StyledInput
        {...{ ref, placeholder }}
        fullWidth
        variant="outlined"
        size="small"
        error={error ? true : false}
        helperText={<StyledError>{error}</StyledError>}
        InputProps={{
          endAdornment,
          startAdornment,
        }}
        {...restProps}
      />
    );
  }
);
