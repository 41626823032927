import React, { useEffect } from "react";

interface Props {
  teamId: any;
  currentPage: "claim" | "registration";
}

export function useRedirect({ teamId, currentPage }: Props) {
  const [isRedirecting, setIsRedirecting] = React.useState(true);

  React.useEffect(() => {
    if (teamId == 84) {
      if (currentPage == "claim") {
        window.location.href = "https://fanhub.seasonshare.com/ambse/landing";
        setIsRedirecting(true);
      }

      if (currentPage == "registration") {
        window.location.href =
          "https://fanhub.seasonshare.com/ambse/general_registration";
        setIsRedirecting(true);
      }
    }

    if (teamId == 53) {
      if (currentPage == "claim") {
        window.location.href = "https://fanhub.seasonshare.com/hawks/landing";
        setIsRedirecting(true);
      }

      if (currentPage == "registration") {
        window.location.href =
          "https://fanhub.seasonshare.com/hawks/general_registration";
        setIsRedirecting(true);
      }
    }
  }, []);

  return { isRedirecting };
}
