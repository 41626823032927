export const sample_cart_data = {
  eventId1: [
    {
      id: 8,
      accountGroupId: "0",
      eventId: "1",
      eventCode: "e1",
      ticketTypeCode: "ttc",
      ticketTypeName: "Adult claim ticket long description is aligned",
      price: "10.28",
      serviceFee: "10",
      priceCode: "_A",
      priceCodeDescription: "A0E1.2",
      className: "A",
      maxCount: 3,
      createdAt: "2021-06-09T01:13:14.000Z",
      updatedAt: "2021-06-09T01:13:14.000Z",
    },
  ],
  eventId2: [
    {
      id: 8,
      accountGroupId: "0",
      eventId: "1",
      eventCode: "e1",
      ticketTypeCode: "ttc",
      ticketTypeName: "Child claim short description",
      price: "0",
      serviceFee: "0",
      priceCode: "_A",
      priceCodeDescription: "A0E1.2",
      className: "A",
      maxCount: 5,
      createdAt: "2021-06-09T01:13:14.000Z",
      updatedAt: "2021-06-09T01:13:14.000Z",
    },
    {
      id: 9,
      accountGroupId: "0",
      eventId: "1",
      eventCode: "e1",
      ticketTypeCode: "ttc",
      ticketTypeName: "Very short desc",
      price: "9.7564",
      serviceFee: "8",
      priceCode: "_A",
      priceCodeDescription: "A0E1.2",
      className: "A",
      maxCount: 0,
      createdAt: "2021-06-09T01:13:14.000Z",
      updatedAt: "2021-06-09T01:13:14.000Z",
    },
  ],
};
