import React, { ReactElement, useEffect, useState, useMemo } from "react";
import {
  Box,
  Grid,
  Paper,
  colors,
  Divider,
  Typography,
  lighten,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";
import { makeStyles } from "@material-ui/styles";
import { UserCheck as UserCheckIcon } from "react-feather";
import AcceptNotice from "./AcceptNotice";
import { useForm, FormProvider } from "react-hook-form";
import { PersonalDetails } from "./PersonalDetails";
import { AdditionalFields } from "./AdditionalFields";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema, creditCardSchema } from "./validationSchema";
import { Submit } from "./Submit";
import { useRegistrationConfig } from "src/registrationConfig";
import { PaymentDetails } from "./PaymentDetails";
import { toast } from "react-toastify";
import axios from "axios";
import swal from "sweetalert2";

type PropTypes = {
  user: any;
};

const errorToast = (error: any): void => {
  toast.error(
    error?.response?.data?.message ||
      error?.response?.data ||
      "Error connecting team account, Please try again."
  );
};

export const RegistrationForm = ({ user }: PropTypes) => {
  const { config } = useConfig();
  const teamId = config.TEAM_ID;
  const { additionalFields, ccEnabled } = useRegistrationConfig(config?.TEAM_ID);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const styles: Record<string, any> = useStyles(config);
  const [extendedValidation, setExtendedValidation] = useState<any>();

  const methods = useForm({
    shouldFocusError: false,
    shouldUnregister: true,
  });
  const { handleSubmit, setValue } = methods;

  const updateEmail = () => {
    setValue("email", "test@test.com");
    if (user?.emailAddress) {
      setValue("email", user?.emailAddress);
    }
  };

  const updateValidationSchema = () => {
    let extended: any = validationSchema;
    additionalFields?.map((field) => {
      extended = validationSchema?.shape({
        [field?.name]: field?.schema,
      });
    });

    if (ccEnabled) {
      Object.keys(creditCardSchema).map((schema) => {
        extended = extended?.shape({
          [schema]: creditCardSchema[schema as keyof typeof creditCardSchema],
        });
      });
    }
    setExtendedValidation(extended);
  };

  const onSubmit = async (values: any) => {
    const { accountNumber } = user;
    const product = "claim";
    const createdAt = new Date();
    const endpoint = `${config.ADMIN_URI}/auth/generalRegister`;
    const { termsAccepted, ccNum, ccExp, ...rest } = values;
    const payload = { teamId, accountNumber, product, createdAt, ...rest };
    if (ccEnabled)
      Object.assign(payload, {
        ccNum: ccNum.replaceAll(" ", ""),
        ccExp: ccExp.replaceAll("/", ""),
      });
    console.log(payload);

    try {
      const response = await axios.post(endpoint, payload);
      if (response.status === 200) {
        toast.success("Successfully submitted");
        window.location.href = `/register_success/${teamId}`;
      }
    } catch (error: any) {
      console.log(error);
      swal.fire({
        icon: "error",
        text:
          error?.response?.data?.errorMessage ||
          error?.response?.data?.errorMessage ||
          "Error connecting team account, Please try again.",
      });
    }
  };

  useEffect(updateValidationSchema, [additionalFields]);
  useMemo(updateEmail, [user]);

  return (
    <FormProvider {...methods}>
      <Paper variant="outlined">
        <Box px={xs ? 2 : 3} py={1} display="flex" justifyContent="flex-start" alignItems="center">
          <UserCheckIcon size={22} />
          <Typography variant="h5" className={styles.formTitle}>
            Contact Information
          </Typography>
        </Box>
        <Divider />
        <Box p={xs ? 3 : 4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={xs ? 3 : 4}>
              <PersonalDetails />
              {ccEnabled && <PaymentDetails />}
              <AdditionalFields />
              <AcceptNotice />
              <Submit />
            </Grid>
          </form>
        </Box>
      </Paper>
    </FormProvider>
  );
};

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    formTitle: {
      fontSize: 22,
      fontFamily: "Poppins",
      fontWeight: 600,
      color: colors.grey[800],
      marginLeft: 10,
    },
    startIcon: {
      color: config?.COLORS.primary,
      fontSize: 20,
    },
    submitButton: {
      backgroundColor: config?.COLORS.primary,
      color: config?.COLORS.contrastText,
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 20,

      "&:hover": {
        backgroundColor: lighten(config?.COLORS.primary, 0.1),
      },
    },
  }))();
