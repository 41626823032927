import React, { ReactNode, Ref, useEffect } from "react";
import { TextField, Typography, makeStyles, colors, Box, Paper } from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";
import { PersonOutlineRounded } from "@material-ui/icons";
import clsx from "clsx";

type PropTypes = {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  className?: Record<string, any>;
  error?: string;
  // Rest props
  [key: string]: any;
};

export const Input = React.forwardRef((props: PropTypes, ref: Ref<HTMLInputElement>) => {
  const { startIcon, endIcon, error, ...restProps } = props;
  const { config } = useConfig();
  const styles: Record<string, any> = useStyles(config);

  return (
    <>
      <Typography variant="subtitle2" className={styles.inputLabel}>
        {props.label} {props.required ? "*" : ""}
      </Typography>
      <TextField
        {...{ ref }}
        {...restProps}
        fullWidth
        className={styles.textField}
        size="small"
        variant="outlined"
        label={""}
        InputProps={{
          endAdornment: <div className={styles?.endIconBox}> {endIcon}</div>,
          startAdornment: startIcon && <div className={styles?.startIconBox}>{startIcon}</div>,
        }}
      />
      <Typography variant="caption" className={styles.errorText}>
        {props?.error}
      </Typography>
    </>
  );
});

// className={styles.endIcon}

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    errorText: {
      color: theme.palette.error.main,
    },
    inputLabel: {
      color: colors.grey[800],
      fontWeight: 600,
      fontFamily: "Poppins",
      marginBottom: 3,
    },

    textField: {
      "& .MuiInputBase-root": {
        padding: 0,

        "&:hover": {
          "& .MuiPaper-root": {
            borderRight: `1px solid rgba(0, 0, 0, 0.87)`,
          },
        },

        "&.Mui-focused": {
          "& .MuiPaper-root": {
            borderRight: `1px solid ${config?.COLORS.primary} !important`,
          },
        },

        "&.Mui-disabled": {
          "& .MuiPaper-root": {
            borderRight: `1px solid rgba(0, 0, 0, 0.23) !important`,
          },
        },
      },

      "& .MuiInputBase-root input": {
        paddingLeft: 50,
      },
      "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: config?.COLORS.primary,
        borderWidth: 1,
      },
      "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F44336",
      },

      "& .MuiFormLabel-root.Mui-focused": {
        color: config?.COLORS.primary,
      },
      "& .MuiFormLabel-root.Mui-error": {
        color: "#F44336",
      },
    },
    startIconBox: {
      // height: "100%",
      // padding: "18.5px 15px",
      position: "absolute",
      height: "100%",
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRight: `1px solid rgba(0, 0, 0, 0.23)`,
      borderRadius: "0",
      color: config?.COLORS.primary,
      fontSize: "18px",
    },
    endIconBox: {
      width: 32,
    },
    errorMessage: {
      marginLeft: 3,
      color: theme.palette.error.main,
    },
  }))();
