import {
  Paper,
  Box,
  Typography,
  Divider,
  Grid,
  useTheme,
  useMediaQuery,
  makeStyles,
  colors,
  lighten,
  Button,
} from "@material-ui/core";
import { CreditCard as CreditCardIcon } from "react-feather";
import { useConfig } from "src/context/ConfigContext";
import {
  SubmitButton,
  CardInput,
  CardExpiry,
  CardCvc,
  StreetAutocomplete,
  ZipCode,
  TermsAgreement,
  CancelButton,
} from "src/components/FormComponents";
import { useFormContext } from "react-hook-form";
import { useWizardCtx } from "../RegistrationFormWizard";
import { InfoAlert } from "./InfoAlert";

export const FormContainer = () => {
  const { submittingPayment, submitIncomplete } = useWizardCtx();
  const { config } = useConfig();
  const teamId = config.TEAM_ID;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const styles: Record<string, any> = useStyles(config);

  return (
    <Paper variant="outlined">
      <Box px={xs ? 2 : 3} py={1} display="flex" justifyContent="flex-start" alignItems="center">
        <CreditCardIcon size={22} />
        <Typography variant="h5" className={styles.formTitle}>
          Payment Details
        </Typography>
      </Box>
      <Divider />
      <Box p={xs ? 3 : 4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InfoAlert />
          </Grid>
          <Grid item xs={12} md={12}>
            <CardInput name="ccNum" />
          </Grid>
          <Grid item xs={12} md={12}>
            <CardExpiry name="ccExp" />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <CardCvc name="ccCvc" />
          </Grid> */}

          <Grid item xs={12} md={6}>
            <StreetAutocomplete addressName="streetAddress" zipName="zipCode" />
          </Grid>
          <Grid item xs={12} md={6}>
            <ZipCode name="zipCode" />
          </Grid>
          <Grid item xs={12}>
            <TermsAgreement name="termsAccepted" />
          </Grid>
          <Grid item xs={6}>
            <CancelButton text="Exit" onClick={submitIncomplete} />
          </Grid>
          <Grid item xs={6}>
            <SubmitButton text="REGISTER" isLoading={submittingPayment} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    formTitle: {
      fontSize: 22,
      fontFamily: "Poppins",
      fontWeight: 600,
      color: colors.grey[800],
      marginLeft: 10,
    },
    startIcon: {
      color: config?.COLORS.primary,
      fontSize: 20,
    },
    submitButton: {
      backgroundColor: config?.COLORS.primary,
      color: config?.COLORS.contrastText,
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 20,

      "&:hover": {
        backgroundColor: lighten(config?.COLORS.primary, 0.1),
      },
    },
  }))();
