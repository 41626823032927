import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { fullSchema, personalSchema } from "./ValidationSchema";
import { useCart } from "src/context/MultiCartContext";

const PaymentCtx = React.createContext();
export const usePaymentCtx = () => React.useContext(PaymentCtx);

export const withFormik =
  (Form) =>
  ({ email, config, handlePurchase, cardDetails }) => {
    const personalDetails = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: email || "",
      termsAgreed: false,
    };

    const ccDetails = {
      street: "",
      apartment: "",
      zipCode: "",
      cardNumber: "",
      cardCvc: "",
      cardExpiry: "",
    };

    const [initialValues, setInitialValues] = useState({
      ...personalDetails,
      ...ccDetails,
    });
    const [validationSchema, setValidationSchema] = useState(personalSchema);
    const [ccOpen, setCCopen] = useState(false);
    const handleCCopen = () => setCCopen(!ccOpen);

    // Changing form fields based on total amount
    // and total seat count
    const { products, grandTotal, totalSeats } = useCart();
    const cartHasAmount = Number(grandTotal) > 0;
    const hasCCconfig = config?.CARD_ON_FILE;

    const updateFormState = (ccEnabled) => {
      if (!ccEnabled) {
        setInitialValues({ ...personalDetails });
        setValidationSchema(personalSchema);
      } else {
        setInitialValues({ ...personalDetails, ...ccDetails });
        setValidationSchema(fullSchema);
      }
    };

    useEffect(() => {
      if (!hasCCconfig) {
        if (cartHasAmount) {
          updateFormState(true);
          setCCopen(true);
        } else {
          updateFormState(false);
          setCCopen(false);
        }
        return;
      }

      if (hasCCconfig) {
        updateFormState(true);
        if (ccOpen) {
          setValidationSchema(fullSchema);
        } else {
          setValidationSchema(personalSchema);
        }
        return;
      }
    }, [cartHasAmount, ccOpen, cardDetails]);

    const formik = useFormik({
      initialValues,
      onSubmit,
      validationSchema,
      enableReinitialize: true,
    });

    const { setSubmitting } = formik;

    async function onSubmit(values) {
      setSubmitting(true);
      if (hasCCconfig && !ccOpen) {
        delete values.cardNumber;
        delete values.cardExpiry;
        delete values.street;
        delete values.cardCvc;
        delete values.apartment;
        delete values.saveCard;
        delete values.zipCode;
        if (cartHasAmount) {
          values.ccSeqId = cardDetails?.ccSeqId;
          values.ccType = cardDetails?.ccType;
        } else {
          delete values.ccSeqId;
          delete values.ccType;
        }
      }
      if (hasCCconfig && ccOpen) {
        delete values.ccSeqId;
        delete values.ccType;
        values.saveCard = true;
      }

      // Bug : termsAgreed in the api request cause failure
      // Fix : avoid sending termsAgreed to the api. Using it only in the frontend
      const { termsAgreed, ...finalValues } = values;

      await handlePurchase(finalValues, products);
      setSubmitting(false);
    }

    return (
      <PaymentCtx.Provider value={{ ccOpen, handleCCopen, cardDetails }}>
        <Form {...{ formik, config }} />
      </PaymentCtx.Provider>
    );
  };
