import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
  makeStyles,
  Paper,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import PaymentIcon from "@material-ui/icons/Payment";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useConfig } from "src/context/ConfigContext";
import { usePaymentCtx } from "./withFormik";

export const CardOnFile = () => {
  const { config } = useConfig();
  const styles = useStyles(config);
  const { handleCCopen, cardDetails } = usePaymentCtx();

  return (
    <>
      {config?.CARD_ON_FILE && cardDetails?.last4Digits && (
        <Paper variant="outlined" className={styles.paper}>
          <ListItem
            className={styles.listItem}
            ContainerProps={{ style: { listStyleType: "none" } }}
          >
            <ListItemIcon>
              <PaymentIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText
              primary={`Card On File : ${cardDetails?.last4Digits}`}
              className={styles.listPrimaryText}
            />
            <ListItemSecondaryAction>
              <IconButton size="small" onClick={handleCCopen}>
                <EditOutlinedIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Paper>
      )}
    </>
  );
};

const useStyles = (config: any) =>
  makeStyles((theme) => ({
    paper: { borderRadius: 0 },
    listItem: {
      "& .MuiListItemIcon-root": {
        minWidth: "40px",
      },
    },
    listPrimaryText: {
      "& .MuiListItemText-primary": {
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: "Poppins",
      },
    },
  }))();
