import React, { ReactElement } from "react";
import { Box, Container, Grid } from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";
import Banner from "src/components/Banner/Banner.jsx";
import Header from "src/components/Header/Header";
import { FailedCard } from "./FailedCard";
import { useHistory } from "react-router-dom";

export const RegistrationFailedPage = () => {
  const history = useHistory();
  const { config } = useConfig();
  const teamId = config.TEAM_ID;

  return (
    <Box>
      <Box>
        <Header />
        <Banner title="" subtitle="" loggedIn="" />
      </Box>

      <Box mt={4}>
        <Container>
          <Grid container justify="center" spacing={4}>
            <Grid item xs={11} sm={6} md={4} lg={4}>
              <FailedCard />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box pb={24} />
    </Box>
  );
};
