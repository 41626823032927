import React from "react";
import OutsideClickHandler from "react-outside-click-handler";

const SuggestionInput = React.forwardRef<HTMLInputElement>((props, ref) => {
  return (
    <input
      ref={ref}
      placeholder="Type Your address here"
      autoFocus={true}
      style={{
        fontFamily: "Poppins, sans-serif",
        fontSize: "14px",
        borderRadius: "6px",
        backgroundColor: "#F9F9F9",
        color: "#384358",
        border: "1px solid #D1D1D1",
        width: "100%",
        height: "2.5rem",
        padding: "0px 1rem",
        boxSizing: "border-box",
      }}
    />
  );
});

const InputPanel = React.forwardRef((props: any, ref: any): JSX.Element => {
  return (
    <div
      style={{
        backgroundColor: "#f9f9f9",
        position: "absolute",
        top: "calc(100% - 12px )",
        width: "100%",
        zIndex: 99,
        padding: "30px 16px",
        boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        borderRadius: "4px",
        boxSizing: "border-box",
      }}
    >
      {props.children}
    </div>
  );
});

type PropTypes = {
  onChange?: (address: { street?: string; zip?: string }) => void;
  children: React.ReactNode;
};

export const MapsAutocomplete = (props: PropTypes) => {
  const inputRef = React.useRef(null);
  const panelRef = React.useRef(null);
  const [panelOpen, setPanelOpen] = React.useState(false);
  let autocomplete: google.maps.places.Autocomplete;

  // Initializing the maps places sdk runs from side effect
  const initAutocomplete = () => {
    if (!inputRef.current && !panelOpen) return;
    let Autocomplete = google?.maps?.places?.Autocomplete;
    autocomplete = new Autocomplete(inputRef.current as any, {
      types: ["address"],
      fields: ["address_components", "geometry", "name"],
    });

    autocomplete?.addListener("place_changed", handleSelect);
  };

  // Cloning the children to add dynamic props to trigger the panel
  const ClonedTrigger = React.cloneElement(props.children as any, {
    onClick: () => {
      setPanelOpen(true);
    },
    onFocus: () => {
      setPanelOpen(true);
    },
  });

  // handler to close the panel on outside click
  const onOutsideClick = () => {
    setPanelOpen(false);
  };

  // address select handler fn triggered by maps sdk
  const handleSelect = () => {
    const place = autocomplete?.getPlace();
    const street = place.name;
    let zip;
    if (!place.geometry) return;
    for (const component of place?.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      if (componentType == "postal_code") {
        zip = component.long_name;
      }
    }
    props?.onChange && props?.onChange({ street, zip });
    setPanelOpen(false);
  };

  // Running side effects
  React.useEffect(initAutocomplete, [inputRef, panelOpen]);

  return (
    <div style={{ position: "relative" }}>
      {ClonedTrigger}
      <OutsideClickHandler {...{ onOutsideClick }}>
        {panelOpen && (
          <InputPanel ref={panelRef}>
            <SuggestionInput ref={inputRef} />
          </InputPanel>
        )}
      </OutsideClickHandler>
    </div>
  );
};
