import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, makeStyles } from "@material-ui/core";
import GenericImage from "../../images/cards/generic.png";
import AmexImage from "../../images/cards/amex.svg";
import VisaImage from "../../images/cards/visa.svg";
import DiscoverImage from "../../images/cards/discover.svg";
import MasterCardImage from "../../images/cards/mastercard.svg";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../../utils/utils";

export default function CardInfo({ formik, config }) {
  const useStyles = makeStyles(() => ({
    inputField: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: config.COLORS.primary,
      },

      "& fieldset": {
        borderRadius: 0,
      },
    },

    cardImage: {
      width: 50,
    },
  }));

  const classes = useStyles();
  const { values, errors, handleChange, dirty, isValid } = formik;
  const [cardImage, setCardImage] = useState(GenericImage);

  const validateCardImage = (cardNumber) => {
    let isVisa = /^4/.test(cardNumber);
    let isMasterCard = /^5[1-5]/.test(cardNumber);
    let isAmex = /^3[47]/.test(cardNumber);
    let isEmpty = cardNumber.length === 0;
    let isUnknown = !isVisa && !isAmex && !isMasterCard;
    let isLengthError = cardNumber.length > 22;

    if (isMasterCard) setCardImage(MasterCardImage);
    if (isVisa) setCardImage(VisaImage);
    if (isAmex) setCardImage(AmexImage);
    if (isUnknown || isEmpty || isLengthError) setCardImage(GenericImage);
  };
  const handleCreditCardChange = (event) => {
    let value = event.target.value;
    validateCardImage(value);
    let formattedValue = formatCreditCardNumber(event.target.value);
    handleChange({ target: { name: "cardNumber", value: formattedValue } });
  };

  const handleCardCvc = (event) => {
    let value = formatCVC(event.target.value);
    handleChange({ target: { name: "cardCvc", value } });
  };

  const handleCardExpiry = (event) => {
    let value = formatExpirationDate(event.target.value);
    handleChange({ target: { name: "cardExpiry", value } });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={6}>
        <TextField
          required
          fullWidth
          className={classes.inputField}
          variant="outlined"
          size="medium"
          name="firstName"
          placeholder="First Name"
          value={values.firstName}
          error={errors.firstName && true}
          helperText={errors.firstName}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TextField
          required
          fullWidth
          className={classes.inputField}
          variant="outlined"
          size="medium"
          name="lastName"
          placeholder="Last Name"
          value={values.lastName}
          error={errors.lastName && true}
          helperText={errors.lastName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <TextField
          required
          fullWidth
          size="medium"
          className={classes.inputField}
          variant="outlined"
          name="cardNumber"
          placeholder="CARD NUMBER"
          value={values.cardNumber}
          error={errors.cardNumber && true}
          helperText={errors.cardNumber}
          InputProps={{
            endAdornment: (
              <img
                alt="Card Type"
                src={cardImage}
                className={classes.cardImage}
              />
            ),
          }}
          onChange={handleCreditCardChange}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TextField
          required
          fullWidth
          className={classes.inputField}
          variant="outlined"
          size="medium"
          name="cardExpiry"
          placeholder="CARD EXPIRY"
          value={values.cardExpiry}
          error={errors.cardExpiry && true}
          helperText={errors.cardExpiry}
          onChange={handleCardExpiry}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TextField
          required
          fullWidth
          className={classes.inputField}
          variant="outlined"
          size="medium"
          name="cardCvc"
          placeholder="CARD CVC"
          value={values.cardCvc}
          error={errors.cardCvc && true}
          helperText={errors.cardCvc}
          onChange={handleCardCvc}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <TextField
          required
          fullWidth
          className={classes.inputField}
          variant="outlined"
          size="medium"
          name="address"
          placeholder="Address"
          value={values.address}
          error={errors.address && true}
          helperText={errors.address}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <TextField
          required
          fullWidth
          className={classes.inputField}
          variant="outlined"
          size="medium"
          name="postalCode"
          placeholder="Postal Code"
          value={values.postalCode}
          error={errors.postalCode && true}
          helperText={errors.postalCode}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}
