import React, { useEffect, memo } from "react";
import {
  Paper,
  Box,
  Typography,
  makeStyles,
  Divider,
  Avatar,
  IconButton,
  colors,
  Grid,
} from "@material-ui/core";
import { DateRangeRounded, DeleteOutline } from "@material-ui/icons";
import Product from "./Product";
import { v4 as uuid } from "uuid";
import { useCart } from "src/context/MultiCartContext";

export default function Event({ config, groupId, title, eventDate }) {
  const styles = useStyles();
  const { resetGroup, products, getGroupTotal } = useCart();

  return (
    <Paper variant="outlined" className={styles.paper}>
      {/* Title */}
      <Box p={2} pb={0} className={styles.paperHead}>
        <Box display="flex" className={styles.titleWrap}>
          <Box>
            <Avatar
              variant="rounded"
              className={styles.avatar}
              style={{ backgroundColor: config.COLORS.primary }}
            >
              <DateRangeRounded className={styles.icon} />
            </Avatar>
          </Box>
          <Box ml={1}>
            <Typography variant="h5" className={styles.title}>
              {title} {eventDate ? `- ${eventDate}` : ""}
            </Typography>
          </Box>
        </Box>
        <Box>
          <IconButton size="small" onClick={() => resetGroup(groupId)}>
            {/* <DeleteOutline /> */}
          </IconButton>
        </Box>
      </Box>

      <Box p={2}>
        <Grid container spacing={3}>
          {products
            .filter((p) => p.groupId == groupId)
            .map((product) => (
              <Grid item xs={12} key={uuid()}>
                <Product {...{ config, product }} />
              </Grid>
            ))}
        </Grid>
      </Box>
      <Divider />
      <Box className={styles.paperFooter} p={1} pl={2} pr={2}>
        <Typography variant="h6" className={styles.footerText}>
          EVENT TOTAL :{" "}
          <span className={styles.totalAmount}>${getGroupTotal(groupId)}</span>
        </Typography>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: "hidden",
    // border: "none",
  },
  paperHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // backgroundColor: colors.grey[50],
    // boxShadow: "0 8px 16px rgb(0 0 0 / 10%)",
  },
  titleWrap: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    // textDecoration: "underline",
  },
  avatar: {
    width: 25,
    height: 25,
  },
  icon: {
    fontSize: 20,
  },
  paperFooter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  footerText: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    color: colors.grey[600],
  },
  totalAmount: {
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 600,
    color: colors.grey[900],
    fontSize: 16,
  },
}));
