import React from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import MatInput from "./MatInput";

export default function BillingDetails({ formik, config }) {
  const classes = useStyles();
  const { values, errors, handleChange } = formik;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={12}>
        <MatInput
          {...{ formik, config }}
          name="fullName"
          placeholder="FULL NAME"
        />
      </Grid>

      <Grid item xs={12} lg={12} className={classes.phoneInputWrapper}>
        <TextField
          value="+1"
          disabled
          className={clsx(classes.phonePrefix, classes.inputField)}
          variant="outlined"
          size="medium"
        />
        <MatInput
          {...{ formik, config }}
          name="phoneNumber"
          placeholder="PHONE NUMBER"
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <MatInput
          {...{ formik, config }}
          name="emailAddress"
          placeholder="EMAIL ADDRESS"
          disabled={true}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  phoneInputWrapper: {
    display: "flex",
  },

  phonePrefix: {
    width: 60,

    "& fieldset": {
      borderRight: "none",
      borderRadius: 0,
    },
  },
}));
