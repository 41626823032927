import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Grid,
  Paper,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  colors,
  IconButton,
  useTheme,
  useMediaQuery,
  Collapse,
} from "@material-ui/core";
import { HighlightOffRounded } from "@material-ui/icons";
import MatInput from "./MatInput";
import cardImages from "src/images/cc-images.png";
import { formatCreditCardNumber, formatCVC } from "src/utils/utils";
import { v4 as uuid } from "uuid";
import { CardOnFile } from "./CardOnFile";
import { usePaymentCtx } from "./withFormik";

export default function CardDetails({ formik, config }) {
  const styles = useStyles(config);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const { setFieldValue, values } = formik;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { ccOpen } = usePaymentCtx();

  const handleDropdownOpen = (open) => setDropdownOpen(open);

  const handleCardInput = (event) => {
    let cardNumber = formatCreditCardNumber(event.target.value);
    setFieldValue("cardNumber", cardNumber);
  };

  const handleCardCvc = (event) => {
    let cardCvc = formatCVC(event.target.value);
    setFieldValue("cardCvc", cardCvc);
  };

  const handleCardExpiryChange = (expiry) => {
    setFieldValue("cardExpiry", expiry);
    setDropdownOpen(false);
  };

  const clearCardExpiry = (event) => {
    event.stopPropagation();
    setFieldValue("cardExpiry", "");
  };

  return (
    <>
      <Box
        mb={2}
        mt={3}
        display="flex"
        alignItems={xs ? "flex-start" : "center"}
        justifyContent={xs ? "flex-start" : "space-between"}
        flexDirection={xs ? "column" : "row"}
      >
        <Typography className={styles.sectionTitle} variant="h6" color="textPrimary">
          Payment Method
        </Typography>
        <img className={styles.cardImages} src={config.CHECKOUT_CARD_IMAGES} alt="" />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MatInput
            required
            name="cardNumber"
            label="Card Number"
            onChange={handleCardInput}
            {...{ formik, config }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className={styles.expiryWrap}>
            <MatInput
              required
              name="cardExpiry"
              label="Card Expiry"
              onClick={() => setDropdownOpen(true)}
              endAdornment={
                values.cardExpiry && (
                  <IconButton onClick={clearCardExpiry}>
                    <HighlightOffRounded />
                  </IconButton>
                )
              }
              {...{ formik, config }}
            />
            {dropdownOpen && (
              <ExpiryDropdown {...{ handleCardExpiryChange, handleDropdownOpen, config }} />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <MatInput
            required
            name="cardCvc"
            label="Security Code"
            onChange={handleCardCvc}
            {...{ formik, config }}
          />
        </Grid>
      </Grid>
    </>
  );
}

// Generating months and years to use in dropdown
let months = [];
let years = [];

for (let i = 1; i <= 12; i++) {
  months.push(i);
}
for (let i = 2021; i <= 2040; i++) {
  years.push(i);
}

// Dropdown panel
function ExpiryDropdown({ handleCardExpiryChange, handleDropdownOpen, config }) {
  const styles = useStyles(config);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [fieldClicked, setFieldClicked] = useState(false);

  useEffect(() => {
    if (month && year) {
      let strMonth = String(month);
      let strYear = String(year);
      let formatedMonth = strMonth.length < 2 ? `0${strMonth}` : strMonth;
      let formatedYear = strYear.slice(2, 4);
      let expiry = `${formatedMonth}/${formatedYear}`;
      handleCardExpiryChange(expiry);
    }
  }, [month, year]);

  return (
    <Paper className={styles.expiryDropdown} elevation={8}>
      <HighlightOffRounded
        className={styles.dropdownClose}
        onClick={() => handleDropdownOpen(false)}
      />
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel className={styles.inputLabel}>Month</InputLabel>
              <Select
                placeholder="Month"
                autoFocus
                className={styles.select}
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              >
                {months.map((month) => (
                  <MenuItem key={uuid()} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel className={styles.inputLabel}>Year</InputLabel>
              <Select
                placeholder="Year"
                className={styles.select}
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                {years.map((year) => (
                  <MenuItem key={uuid()} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

const useStyles = (config) => {
  return makeStyles((theme) => ({
    sectionTitle: {
      fontSize: 18,
      fontFamily: "Poppins",
      fontWeight: 700,
    },

    cardImages: {
      width: 150,
      height: "100%",
    },
    expiryWrap: {
      position: "relative",
    },

    expiryDropdown: {
      position: "absolute",
      top: "-12px",
      width: "100%",
      zIndex: 999,
      // borderRadius: 0,
    },

    select: {
      // backgroundColor: colors.grey[200],
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,

      "&:after": {
        borderBottom: `2px solid ${config.COLORS.primary}`,
      },

      "&:before": {
        borderBottom: `1px solid ${config.COLORS.primary}`,
      },

      "& .MuiSelect-root": {
        paddingLeft: 10,
      },
    },

    inputLabel: {
      // zIndex: 999,
      paddingLeft: 8,
      "&.MuiInputLabel-shrink": {
        color: config.COLORS.primary,
      },
    },
    dropdownClose: {
      position: "absolute",
      top: 5,
      right: 5,
      cursor: "pointer",
      color: colors.grey[400],
    },
  }))();
};
