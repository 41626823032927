import React, { useState, ReactElement, useEffect, Ref } from "react";
import {
  ButtonGroup,
  Button,
  Box,
  makeStyles,
  Typography,
  colors,
  Grid,
  Checkbox,
} from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";
import { DoneAllRounded } from "@material-ui/icons";
import { v4 as uuid } from "uuid";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";

type PropTypes = {
  label: string;
  options: string[];
  onChange: Function;
  error?: string;
  // Rest props
  [k: string]: any;
};

export const CheckboxGroup = React.forwardRef((props: PropTypes, ref: Ref<HTMLElement>) => {
  const { label, options, error, name } = props;
  const { config }: Record<string, any> = useConfig();
  const styles = useStyles(config);
  const [selected, setSelected] = useState<string>("");
  const { getValues } = useFormContext();
  const values = getValues();

  const handleSelect = (option: string) => {
    const event = { target: { name, value: option } };
    setSelected(event?.target?.value);
    props?.onChange(event);
  };

  return (
    <>
      <Typography variant="subtitle2" className={styles.inputLabel}>
        {label}
      </Typography>

      <Grid container spacing={2}>
        {options.map((option: string) => (
          <Grid key={uuid()} item xs="auto">
            <Button
              onClick={() => handleSelect(option)}
              fullWidth
              disableRipple
              className={clsx(styles.button, values[props?.name] === option ? styles.checked : "")}
              disableElevation
              variant="contained"
              size="small"
              color="primary"
              startIcon={
                <Checkbox
                  disableRipple
                  checked={values[props?.name] === option}
                  className={styles.checkbox}
                />
              }
            >
              {option}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Typography variant="caption" className={styles.errorText}>
        {error}
      </Typography>
    </>
  );
});

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    errorText: {
      color: theme.palette.error.main,
    },
    inputLabel: {
      color: colors.grey[800],
      fontWeight: 600,
      fontFamily: "Poppins",
      marginBottom: 8,
    },
    button: {
      backgroundColor: "transparent",
      color: colors.grey[600],
      border: `1px solid rgba(0, 0, 0, 0.23)`,
      fontFamily: "Poppins",
      textTransform: "capitalize",
      fontSize: 14,
      padding: 0,
      paddingRight: 12,

      "&:hover": {
        backgroundColor: colors.grey[100],
      },

      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      },

      "& .MuiButton-startIcon": {
        height: "100%",
        display: "flex",
        padding: "2px 2px",
        paddingLeft: 3,
        alignItems: "center",
        justifyContent: "center",
        borderRight: `1px solid rgba(0, 0, 0, 0.23)`,
      },
    },

    checked: {
      border: `1px solid ${config?.COLORS.primary}`,
      color: config?.COLORS.primary,

      "& .MuiSvgIcon-root": {
        color: theme.palette.success.main,
      },

      "& .MuiButton-startIcon": {
        borderRight: `1px solid ${config?.COLORS.primary}`,
      },
    },

    checkbox: {
      padding: 0,

      "& .MuiSvgIcon-root": {
        color: colors.grey[400],
      },

      "&.Mui-checked .MuiSvgIcon-root": {
        color: config?.COLORS.primary,
      },
    },
  }))();
