import { useFormContext } from "react-hook-form";
import {
  User as UserIcon,
  Mail as MailIcon,
  UserCheck as UserCheckIcon,
  Smartphone as SmartPhoneIcon,
} from "react-feather";
import { Grid } from "@material-ui/core";
import { Input } from "src/components/Input";
import { FiUser, FiMail } from "react-icons/fi";

export const PersonalDetails = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Input
          placeholder="Enter first name"
          label="First Name"
          startIcon={<FiUser />}
          error={errors?.firstName?.message}
          {...register("firstName", { required: "Please enter First Name." })}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Input
          placeholder="Enter last name"
          label="Last Name"
          startIcon={<FiUser />}
          error={errors?.lastName?.message}
          {...register("lastName", { required: "Please enter Last Name" })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          disabled={true}
          placeholder="Enter email"
          label="Email Address"
          startIcon={<FiMail />}
          error={errors?.email?.message}
          {...register("email", { required: "Please enter your email address." })}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Input
          placeholder="Enter phone number"
          label="Phone Number"
          startIcon={<>+1</>}
          error={errors?.phoneNumber?.message}
          {...register("phoneNumber", {
            required: "Please enter Phone Number",
            minLength: {
              value: 10,
              message: "Please enter a valid phone number.",
            },
            maxLength: {
              value: 10,
              message: "Please enter a valid phone number.",
            },
            pattern: {
              value:
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
              message: "Please enter a valid phone number.",
            },
          })}
        />
      </Grid>
    </>
  );
};
