import axios from "axios";
import moment from "moment-timezone";

export const connectTeamAccount = async (config, claimPlanCode, isMulti) => {
  const teamAuthUrl = `${config.ADMIN_URI}/auth/url/${config.TEAM_ID}`;
  let redirectUri = `${config.SS_FE_BASE_URI}/tmcallback/login/${config.TEAM_ID}/${claimPlanCode}`;

  const teamAuthResponse = await axios.get(teamAuthUrl);
  const authUrl = teamAuthResponse.data.url;
  const tmUrl = authUrl
    .replace("REDIRECT_URL", redirectUri)
    .replace("STATE", `LOGIN-${config.TEAM_ID}`);
  return tmUrl;
};

export const login = async (claimPlanCode, history, config) => {
  const params = new URLSearchParams(history.location.search);
  const authCode = params.get("code");
  const teamId = config.TEAM_ID;
  const redirectUri = `${config.SS_FE_BASE_URI}/tmcallback/login/${config.TEAM_ID}/${claimPlanCode}`;
  const currentMoment = moment().utc().format(); // send UTC timezone to backend
  const timeZone = moment.tz.guess();

  const response = await axios.post(`${config.ADMIN_URI}/auth/login/v2`, {
    authCode,
    teamId: parseInt(teamId, 10),
    redirectUri,
    claimPlanCode,
    clientMoment: currentMoment,
    clientTimezone: timeZone,
  });
  return response;
};

export const sellClaimTickets = async (values, config) => {
  const response = await axios.post(`${config.ADMIN_URI}/claim/add`, values);
  console.log(response);
  return response;
};
