import React from "react";
import { TextField, makeStyles, Box, Typography } from "@material-ui/core";
import config from "src/config";
import clsx from "clsx";

export default function MatInput({
  name,
  label,
  value,
  placeholder,
  formik,
  required,
  disabled,
  onChange,
  autoComplete,
  endAdornment,
  onFocus,
  onBlur,
  onClick,
  className,
  autoFocus,
  size,
  style,
  config,
}) {
  const styles = useStyles(config);
  const { values, handleChange, errors } = formik || {};

  return (
    <>
      <TextField
        inputProps={{
          autocomplete: "new-password",
          form: {
            autocomplete: "off",
          },
        }}
        autoFocus={!!autoFocus || false}
        className={clsx(styles.inputField, className)}
        variant="outlined"
        fullWidth
        disabled={!!disabled}
        required={!!required}
        value={value || formik?.values[name]}
        onChange={onChange || formik.handleChange}
        error={!!formik?.errors[name] && formik.submitCount > 0}
        {...{
          name,
          label,
          size,
          autoComplete,
          onFocus,
          onBlur,
          onClick,
          placeholder,
          style,
        }}
        InputProps={{
          endAdornment: endAdornment,
        }}
      />
      {!!formik?.errors[name] && formik.submitCount > 0 && (
        <Box>
          <Typography variant="caption" className={styles.errorCaption}>
            {errors[name]}
          </Typography>
        </Box>
      )}
    </>
  );
}

const useStyles = (config) => {
  return makeStyles((theme) => ({
    inputField: {
      "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: config.COLORS.primary,
      },
      "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F44336",
      },

      "& fieldset": {
        borderRadius: 0,
      },

      "& .MuiFormLabel-root.Mui-focused": {
        color: config.COLORS.primary,
      },
      "& .MuiFormLabel-root.Mui-error": {
        color: "#F44336",
      },
    },
    errorCaption: {
      color: "#F44336",
      marginLeft: 1,
    },
  }))();
};
