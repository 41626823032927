import React from "react";
import { Box, Container, Grid } from "@material-ui/core";
import FloatingPanel from "./FloatingPanel";
import MultiProductCart from "src/components/MultiProductCart";

export default function CartView({ config, setCheckout }) {
  return (
    <>
      <Container>
        <Box mt={4} mb={24}>
          <Grid container spacing={4} justify="center">
            <Grid item xs={12} lg={7}>
              <MultiProductCart {...{ config }} />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <FloatingPanel {...{ config, setCheckout }} />
    </>
  );
}
