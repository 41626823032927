import React, { useEffect, useState } from "react";
import getConfig from "src/config";
import { useHistory } from "react-router-dom";
import { useConfig } from "src/context/ConfigContext";

export const withValidateRoute =
  (Page) =>
  ({ match }) => {
    const history = useHistory();
    const [validated, setValidated] = useState(undefined);
    const { updateConfig, config } = useConfig();

    const validate = () => {
      let { teamId } = match?.params;
      let config = getConfig(teamId);
      let registerEnabled = config.REGISTRATION_ENABLED;
      let isRegisterRoute = history.location.pathname.includes("register");
      updateConfig(config);

      if (isRegisterRoute && !registerEnabled) {
        setValidated(false);
        return;
      }

      if (!isRegisterRoute && teamId === "53") {
        setValidated(false);
        return;
      }

      config.TEAM_ID ? setValidated(true) : setValidated(false);
    };

    const redirect404 = () => {
      validated !== undefined && !validated && history.push("/");
    };

    const initIntercom = () => {
      try {
        const { teamId } = match?.params;
        let config = getConfig(teamId);

        config.INTERCOM_ENABLED &&
          window.Intercom("boot", {
            app_id: "xol8gfkc",
          });
      } catch (error) {
        console.log("error while booting intercom");
      }
    };

    useEffect(validate, []);
    useEffect(redirect404, [validated]);

    // adding intercom support if enabled in config
    useEffect(initIntercom, [match]);

    return <>{validated && <Page {...{ match }} />}</>;
  };
