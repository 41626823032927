import React, { ReactElement, createElement } from "react";
import {
  Box,
  Paper,
  makeStyles,
  Typography,
  Hidden,
  Button,
  fade,
  colors,
} from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";
import { useHistory } from "react-router-dom";
import { PlanType } from "./team_options";
import { RegistrationPlan } from "src/types";

type PropTypes = {
  handlePlanSelect: Function;
  plan: RegistrationPlan;
};

export default function PlanCard({ handlePlanSelect, plan }: PropTypes): ReactElement {
  const history = useHistory();
  const { config } = useConfig();
  const styles = useStyles(config);
  return (
    <Paper variant="outlined" className={styles.paper}>
      <Paper variant="outlined" className={styles.wrapper}>
        <Box className={styles.planContent}>
          <Box p={2} textAlign="center" width="100%" boxSizing="border-box">
            <Typography variant="h5" className={styles.planName}>
              {plan.planName}
            </Typography>
          </Box>
          <Box flexGrow={1} />
          <Box p={2} textAlign="center">
            <Typography variant="subtitle2" className={styles.planDescription}>
              {plan.description}
            </Typography>
          </Box>
          <Box p={2} width="100%" boxSizing="border-box">
            <Button
              size="large"
              fullWidth
              disableElevation
              variant="contained"
              className={styles.button}
              onClick={() => handlePlanSelect()}
            >
              Sign Up Now
            </Button>
          </Box>
        </Box>
        <Box className={styles.bgShape} />
      </Paper>
    </Paper>
  );
}

const useStyles = (config: Record<string, any>) =>
  makeStyles((theme) => ({
    paper: {
      padding: 16,
      height: "100%",
      boxSizing: "border-box",
      transition: "all 0.3s ease",
      borderRadius: 8,
      "&:hover": {
        boxShadow: "0 5px 16px rgb(0 0 0 / 10%)",
      },
    },
    wrapper: {
      position: "relative",
      borderRadius: 4,
      overflow: "hidden",
      border: "none",
      //   borderBottomLeftRadius: 0,
      //   borderBottomRightRadius: 0,
    },
    bgShape: {
      position: "absolute",
      top: 0,
      width: "100%",
      height: "40%",
      backgroundColor: config?.COLORS.secondary,
      clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 70%)`,
    },
    planContent: {
      position: "relative",
      zIndex: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexDirection: "column",
      boxSizing: "border-box",
      minHeight: 350,
    },
    planName: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 22,
      color: config?.COLORS.secondaryContrastText,
    },

    planDescription: {
      color: colors.grey[500],
      fontFamily: "Poppins",
    },

    button: {
      backgroundColor: config?.COLORS.primary,
      color: config?.COLORS.contrastText,
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: 16,

      "&:hover": {
        backgroundColor: fade(config?.COLORS.primary, 0.8),
      },
    },
  }))();
