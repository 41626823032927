import { Box, Grid, Typography } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { InputField } from "./InputField";
import { User as UserIcon, Smartphone as PhoneIcon, Mail as MailIcon } from "react-feather";
import { useCheckoutCtx } from "./CheckoutFormV2";
import { useEffect } from "react";

export const PersonalDetails = () => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { email, firstName, lastName, phoneNumber } = useCheckoutCtx();

  useEffect(() => {
    setValue("email", email);
    setValue("firstName", firstName);
    setValue("lastName", lastName);
    setValue("phoneNumber", phoneNumber);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontSize: 18,
            fontFamily: "Poppins",
            fontWeight: 700,
          }}
          variant="h6"
          color="textPrimary"
        >
          Personal Information
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          // disabled={true}
          placeholder="First Name"
          error={errors?.firstName?.message}
          endAdornment={<UserIcon />}
          {...register("firstName", {
            required: "Please enter First Name.",
          })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          placeholder="Last Name"
          error={errors?.lastName?.message}
          endAdornment={<UserIcon />}
          {...register("lastName", {
            required: "Please enter Last Name.",
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <InputField
          placeholder="Cell Phone (Numbers Only)"
          error={errors?.phoneNumber?.message}
          startAdornment={
            <Box
              style={{
                paddingRight: "4px",
                marginTop: "-2px",
                color: "rgba(0, 0, 0, 0.38)",
              }}
            >
              +1
            </Box>
          }
          endAdornment={<PhoneIcon />}
          {...register("phoneNumber", {
            required: "Please enter Phone Number",
            minLength: {
              value: 10,
              message: "Please enter a valid phone number.",
            },
            maxLength: {
              value: 10,
              message: "Please enter a valid phone number.",
            },
            pattern: {
              value:
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
              message: "Please enter a valid phone number.",
            },
          })}
        />
      </Grid>

      <Grid item xs={12}>
        <InputField
          disabled={true}
          placeholder="Email Address"
          error={errors?.email?.message}
          endAdornment={<MailIcon />}
          {...register("email", {
            required: "Please enter email address.",
          })}
        />
      </Grid>
    </Grid>
  );
};
