import React, { useEffect, useState, useMemo } from "react";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import Banner from "src/components/Banner/Banner";
import Header from "src/components/Header/Header";
import getConfig from "src/config/index";
import { useHistory } from "react-router";
import CartView from "./CartView";
import CheckoutView from "./CheckoutView";
import Preloader from "./Preloader";
import { useCart } from "src/context/MultiCartContext";
import { sample_cart_data } from "./sample_cart_data";
import Swal from "sweetalert2";
import ConnectTeamAccount from "./ConnectTeamAccount";
import ProductError from "./ProductError";
import LoginNotice from "../../components/LoginNotice/LoginNotice";
import PurchasePopup, { usePurchasePopup } from "./PurchasePopup";
import { sellClaimTickets } from "../../api/api";
import { successfulPurchases, failedPurchases } from "./sample_popup_data";
import swal from "sweetalert2";
import { useRedirect } from "src/hooks/useRedirect";

export default function AllClaimPage({ match }) {
  let [loading, setLoading] = useState(false);
  let [checkout, setCheckout] = useState(false);
  let { teamId, claimPlanCode } = match?.params;
  let config = getConfig(teamId);
  let { initCart } = useCart();
  const [isError, setIsError] = useState(false);
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const history = useHistory();
  const {
    handlePopup,
    popupOpen,
    successData,
    failedData,
    updatePopupData,
    handleFinish,
  } = usePurchasePopup({
    config,
    claimPlanCode,
  });
  const [cardDetails, setCardDetails] = useState();

  // useEffect(() => {
  //   console.log(cardDetails);
  // }, [cardDetails]);
  // useEffect(() => console.log(user), [user]);

  // useEffect(() => initCart(sample_cart_data), [user]);

  const formatExpiryDate = (expiryDate) => {
    if (!expiryDate || !expiryDate.includes("/")) {
      return expiryDate;
    }

    const expiryArray = expiryDate.split("/");
    let month = expiryArray.length > 0 ? expiryArray[0] : "";
    let year = expiryArray.length > 1 ? expiryArray[1] : "";

    if (month.length === 1) {
      month = `0${month}`;
    }

    if (year.length > 1) {
      year = year.slice(-2);
    }

    return `${month}${year}`;
  };

  // useEffect(() => {
  //   updatePopupData(successfulPurchases, failedPurchases);
  //   handlePopup(true, { successfulPurchases, failedPurchases });
  // }, []);

  const handlePurchase = async (values, products) => {
    try {
      const data = { ...values };
      data.accountNumber = user.accountNumber;
      data.teamId = config.TEAM_ID;
      if (data.cardNumber) {
        data.cardNumber = data?.cardNumber?.replaceAll(" ", "");
      }

      if (data.firstName && data.lastName) {
        data.firstName = data.firstName.replace(/['"]+/g, "");
        data.lastName = data.lastName.replace(/['"]+/g, "");
      }

      // data.cardCvc = "111";
      if (data.cardExpiry) {
        data.cardExpiry = formatExpiryDate(data.cardExpiry);
      }

      const finalProducts = products.filter(
        (product) => parseInt(product.numSeats) > 0
      );
      data.products = finalProducts;

      let response = await sellClaimTickets(data, config);
      if (!(response.status === 200)) return;
      let { isAllSuccess, successfulPurchases, failedPurchases } =
        response.data;
      isAllSuccess && history.push(`/thank-you/${teamId}`);
      !isAllSuccess && updatePopupData(successfulPurchases, failedPurchases);
      !isAllSuccess && handlePopup(true);

      return Promise.resolve(response);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        "Something went wrong. Please contact support if the error persists.";
      Swal.fire("Error!", errorMessage, "error");

      return Promise.resolve(error);
    }
  };

  const email = user?.emailAddress || "";
  const firstName = user?.firstName || "";
  const lastName = user?.lastName || "";
  const phoneNumber = user?.phoneNumber || "";

  // .edu email validation and error handling
  const validateEduEmail = async () => {
    const dotEduCheck = config?.DOT_EDU_CHECK;
    const email = user?.emailAddress;
    if (email) {
      let isEdu = email.substring(email.length - 4) == ".edu";
      if (!isEdu && dotEduCheck) {
        setUser(null);
        let tmLogOutUrl = `https://auth.ticketmaster.com/archtics/sign-out?redirectUrl=${config?.SS_FE_BASE_URI}/claim-ticket/all/${config?.TEAM_ID}/${claimPlanCode}`;
        await swal
          .fire({
            icon: "error",
            html: "<p>This program is only available to current college or graduate school students. Please use your official school’s email address (@.edu) to complete your registration.</p>",
          })
          .then((res) => {
            if (res.isConfirmed) {
              if (window && window?.location) {
                window.location.href = tmLogOutUrl;
              } else {
                document.location.href = tmLogOutUrl;
              }
            }
          });
        // window.location.href = `/claim-ticket/all/${teamId}/${claimPlanCode}`;
      }
    }
  };

  // card on file error modal
  const cardErrorModal = async () => {
    const cardErrorEnabled = config?.CARD_ON_FILE_ERROR;
    const email = user?.emailAddress;
    if (!email || !cardErrorEnabled) return;
    if (!cardDetails || !cardDetails?.last4Digits) {
      setUser(null);
      await swal.fire({
        icon: "error",
        html: `<p>You have not completed your registration. Please go to <a href='https://claim.seasonshare.com/register/${config?.TEAM_ID}' target='_blank' >https://claim.seasonshare.com/register/${config?.TEAM_ID}</a>, come back here after completing your registration.</p>`,
      });
      window.location.href = `/claim-ticket/all/${teamId}/${claimPlanCode}`;
    }
  };

  useEffect(() => {
    cardErrorModal();
    validateEduEmail();
  }, [user]);

  const { isRedirecting } = useRedirect({ teamId, currentPage: "claim" });

  return (
    <>
      {!isRedirecting ? (
        <>
          {/* <script>
        {window.confirm("Are you sure want to leave ?")}
        {window.addEventListener("popstate", function () {
          window.confirm("Are you sure want to leave ?");
        })}
      </script> */}

          <PurchasePopup
            {...{ config, popupOpen, successData, failedData, handleFinish }}
          />
          <Box>
            <Header {...{ config }} />
            <Banner
              {...{ config, loggedIn }}
              title={config.PAGE_TITLES.claimTicket.title}
              subtitle={config.PAGE_TITLES.claimTicket.subtitle}
            />
          </Box>

          {!loggedIn && (
            <ConnectTeamAccount
              {...{
                config,
                claimPlanCode,
                setUser,
                setLoggedIn,
                initCart,
                setCardDetails,
              }}
            />
          )}

          {loggedIn && (
            <Container>
              <Grid container justify="center">
                <Grid item xs={12} lg={7}>
                  <Box pt={4}>
                    <LoginNotice
                      accountUserName={user?.emailAddress}
                      accountNumber={user?.accountNumber}
                      config={config}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          )}

          {loading && <Preloader {...{ config }} />}

          {!loading && loggedIn && isError ? (
            <ProductError />
          ) : (
            <>
              {!loading && !isError && loggedIn && checkout && (
                <CheckoutView
                  {...{
                    config,
                    setCheckout,
                    handlePurchase,
                    email,
                    firstName,
                    lastName,
                    phoneNumber,
                    cardDetails,
                  }}
                />
              )}
              {!loading && !isError && loggedIn && !checkout && (
                <CartView {...{ config, setCheckout }} />
              )}
            </>
          )}
          <Box mb={24} />
        </>
      ) : null}
    </>
  );
}
