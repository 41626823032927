/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import getConfig from "../../config/index";
import ThankYou from "../../components/ThankYouPage/ThankYou";

const ThankYouContainer = ({ match }) => {
  const { teamId } = match?.params;
  const config = getConfig(teamId);

  return (
    <div>
      <ThankYou config={config} />
    </div>
  );
};

export default ThankYouContainer;
