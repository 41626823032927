import React, { useEffect } from "react";
import {
  Box,
  makeStyles,
  Container,
  Grid,
  Typography,
  fade,
} from "@material-ui/core";
import { useConfig } from "src/context/ConfigContext";

export default function Banner({ title, subtitle, loggedIn }) {
  const { config } = useConfig();
  const styles = useStyles(config, loggedIn);

  return (
    <Box className={styles.banner}>
      <Box className={styles.overlay}></Box>
      <Box className={styles.textContainer}>
        <Container>
          <Box textAlign="center">
            <Typography variant="h3" className={styles.title}>
              {title}
            </Typography>
          </Box>
          <Box textAlign="center" mt={1}>
            <Typography variant="subtitle1" className={styles.subtitle}>
              {subtitle}
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

const useStyles = (config, loggedIn) => {
  return makeStyles((theme) => ({
    banner: {
      // height: loggedIn ? 150 : 450,
      height: 450,
      width: "100%",
      backgroundImage: `url(${config?.HOME_HERO_BG})`,
      backgroundSize: "cover",
      backgroundBlendMode: "overlay",
      backgroundPosition: "center",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      [theme.breakpoints.down("sm")]: {
        //height: loggedIn ? 150 : 350,
        height: 300,
      },
      [theme.breakpoints.down("xs")]: {
        //height: loggedIn ? 150 : 200,
        height: 200,
      },
    },
    overlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: `rgba(0,0,0,${config?.COLORS.bannerOverlay})`,
      zIndex: 10,
    },
    textContainer: {
      zIndex: 11,
    },
    title: {
      color: config?.COLORS.contrastText,
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: loggedIn ? 32 : 48,
      lineHeight: 1.2,

      [theme.breakpoints.down("sm")]: {
        fontSize: loggedIn ? 32 : 48,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 24,
      },
    },
    subtitle: {
      fontFamily: "Poppins",
      color: fade("#ffffff", 0.8),
      fontSize: loggedIn ? 20 : 26,
      fontWeight: 300,
      lineHeight: 1.2,

      [theme.breakpoints.down("sm")]: {
        fontSize: loggedIn ? 20 : 24,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 18,
      },
    },
  }))();
};
