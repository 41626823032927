import { InputField } from "./utils/InputField";
import { useFormContext } from "react-hook-form";
import { XCircle as CloseIcon, MapPin as MapIcon } from "react-feather";
import { MapsAutocomplete } from "./utils/MapsAutocomplete";
import { IconButton } from "@material-ui/core";
import { IoLocationOutline } from "react-icons/io5";

type PropTypes = {
  addressName: string;
  zipName: string;
};

export const StreetAutocomplete = ({ addressName, zipName }: PropTypes) => {
  const {
    register,
    formState: { errors, submitCount },
    watch,
    setValue,
    trigger,
  } = useFormContext();

  const handleAddressChange = (address: { street?: string; zip?: string }) => {
    const { street, zip } = address;
    setValue(addressName, street ? street : "");
    setValue(zipName, zip ? zip : "");
    submitCount > 0 && trigger(addressName);
    submitCount > 0 && trigger(zipName);
  };

  const clearAddress = (event: any) => {
    event.stopPropagation();
    setValue(addressName, "");
    setValue(zipName, "");
    submitCount > 0 && trigger(addressName);
    submitCount > 0 && trigger(zipName);
  };

  return (
    <MapsAutocomplete onChange={handleAddressChange}>
      <InputField
        {...register(addressName, {
          required: "Please enter your street",
        })}
        // label="Street Address"
        role={"presentation"}
        // label="Street Address"
        placeholder="Street Address"
        error={errors[addressName]?.message}
        endAdornment={
          watch(addressName) ? (
            <IconButton size={"small"} onClick={clearAddress}>
              <CloseIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
            </IconButton>
          ) : (
            <MapIcon />
          )
        }
      />
    </MapsAutocomplete>
  );
};
